import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    class: "up-confirm"
  }, _ctx.$attrs, {
    center: "",
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }), _createSlots({
    _: 2
  }, [_renderList(_ctx.$slots, (index, name) => {
    return {
      name: name,
      fn: _withCtx(() => [_renderSlot(_ctx.$slots, name)])
    };
  })]), 1040);
}