import { OAuthProvider } from '@/service/enum'

export const AUTH0_CONFIG = {
  domain: 'auth.wallet.unipass.id',
  clientID: 'vr6KIghxCqmElpAd4TND0nrMBiAR3X2m',
  responseType: 'token id_token',
  redirectUri: window.location.origin + '/loading',
}

export const getConnectionByProvider = (provider: OAuthProvider) => {
  switch (provider) {
    case OAuthProvider.GOOGLE:
    case OAuthProvider.AUTH0:
      return undefined
    case OAuthProvider.APPLE:
      return 'apple'
    case OAuthProvider.UNIPASS:
      return 'unipass'
    default:
      return undefined
  }
}

// account
export const checkProvider = async () => {}
