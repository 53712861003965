import cassava from '@/assets/third-party/cassava/cassava.json';
import DAOLink from '@/assets/third-party/DAOLink/DAOLink.json';
import mufex from '@/assets/third-party/mufex/mufex.json';
import KCC from '@/assets/third-party/KCC/index.json';
const thirdPartConfig = {
  theme: {
    darkList: ['cassava', 'DAOLink', 'mufex', 'KCC'],
    lightList: ['metacene']
  },
  lang: {
    cassava,
    DAOLink,
    mufex,
    KCC
  },
  components: {
    default: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/default.vue');
      });
    },
    cassava: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/cassava/cassava.vue');
      });
    },
    DAOLink: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/DAOLink/DAOLink.vue');
      });
    },
    metacene: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/metacene/metacene.vue');
      });
    },
    mufex: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/mufex/mufex.vue');
      });
    },
    KCC: () => {
      return defineAsyncComponent(() => {
        return import('@/assets/third-party/KCC/index.vue');
      });
    }
  }
};
export default thirdPartConfig;