import { UPMessage } from '@unipasswallet/popup-types'

export const vuplexConnectReady = (cb: (value: UPMessage) => void) => {
  if (!window?.vuplex) return
  window.onConnectPageReady = (connectValue: UPMessage) => {
    try {
      cb(connectValue)
    } catch (err) {
      console.error('vuplexConnectReady', err)
    }
  }
  window.vuplex.postMessage('onConnectReady')
}

export const vuplexSignMessageReady = (cb: (value: UPMessage) => void) => {
  if (!window?.vuplex) return
  window.onSignMessagePageReady = (signMessageValue: UPMessage) => {
    try {
      cb(signMessageValue)
    } catch (err) {
      console.error('vuplexSignMessageReady', err)
    }
  }
  window.vuplex.postMessage('onSignMessageReady')
}

export const vuplexSendTransactionReady = (cb: (value: UPMessage) => void) => {
  if (!window?.vuplex) return
  window.onSendTransactionPageReady = (sendTransactionValue: UPMessage) => {
    try {
      cb(sendTransactionValue)
    } catch (err) {
      console.error('vuplexSendTransactionReady', err)
    }
  }
  window.vuplex.postMessage('onSendTransactionReady')
}
