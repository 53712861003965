import i18n from '@/plugins/i18n';
import thirdPartThemeConfig from '@/assets/third-party/theme-config';
const darkList = thirdPartThemeConfig.theme.darkList;
const lightList = thirdPartThemeConfig.theme.lightList;
const themeList = darkList.concat(lightList);
export const initTheme = () => {
  let theme = sessionStorage.theme;

  if (!theme) {
    if (window.location.hostname.startsWith('kcc')) {
      // for kcc domain
      theme = 'KCC';
      sessionStorage.theme = 'KCC';
    } else {
      sessionStorage.removeItem('theme');
    }
  }

  if (theme) {
    const isDark = useDark();
    const toggleDark = useToggle(isDark);

    if (theme === 'dark') {
      toggleDark(true);
    } else if (theme === 'light') {
      toggleDark(false);
    } else if (themeList.includes(theme)) {
      // dark
      if (darkList.includes(theme)) {
        toggleDark(true);
      } // light


      if (lightList.includes(theme)) {
        toggleDark(false);
      } // set theme html id


      const html = document.querySelector('html');
      if (html) html.classList.add(theme); // set locale

      for (const locale of i18n.global.availableLocales) {
        if (locale === theme) {
          var _localStorage;

          i18n.global.locale.value = theme;
          (_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.setItem('language', theme);
        }
      }
    }
  }
};