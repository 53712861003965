import { toUtf8Bytes, toUtf8String } from 'ethers/lib/utils'

import { encryptKeystore, decryptKeystore } from '@unipasswallet/utils'

export async function safeEncryptKeystore(
  originKey: string,
  // params: HMacKMSParams,
  password: string,
): Promise<string> {
  // const hmac = await generateHMacByKMS(params, password)
  const encryptedKeystore = await encryptKeystore(toUtf8Bytes(originKey), password, {
    scrypt: { N: 16 },
  })
  return encryptedKeystore
}

export async function safeDecryptKeyStore(
  encryptedKeystore: string,
  // params: HMacKMSParams,
  password: string,
): Promise<string> {
  // const hmac = await generateHMacByKMS(params, password)
  const originKey = await decryptKeystore(encryptedKeystore, password)
  return toUtf8String(originKey)
}
