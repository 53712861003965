import { sortBy } from 'lodash-es'
import { TokenInfo } from '@/service/chains-config'
import { getAddress } from 'ethers/lib/utils'
import { ADDRESS_ZERO } from '@/service/constants'
import { isSameAddress } from '../string-utils'

export const combineTokens = (
  tokensA: Array<TokenInfo>,
  tokensB: Array<TokenInfo>,
): Array<TokenInfo> => {
  if (tokensA.length === 0 || tokensB.length === 0) return [...tokensA, ...tokensB]

  const tokens = [...tokensA, ...tokensB]

  return tokens.reduce((result: Array<TokenInfo>, current: TokenInfo) => {
    const existing = result.find(
      (item) => item.contractAddress.toLowerCase() === current.contractAddress.toLowerCase(),
    )
    if (existing) {
      Object.assign(existing, {
        ...current,
        symbol: existing.default ? existing.symbol : current.symbol,
        name: existing.default ? existing.name : current.name,
      })
    } else {
      result.push({ ...current, contractAddress: getAddress(current.contractAddress) })
    }

    return result
  }, [])
}

export const filterRemovedAddress = (tokens: Array<TokenInfo>, addresses: Array<string>) => {
  if (!tokens.length || !addresses.length) return tokens

  return tokens.filter(
    (token) =>
      !addresses.find((address) => isSameAddress(token.contractAddress, address)) ||
      token.contractAddress === ADDRESS_ZERO,
  )
}

export const sortValuableTokens = (tokens: Array<TokenInfo>): Array<TokenInfo> => {
  // sort by
  // 1. default coin
  // 2. valuable coin with dollar worth (balance * price)
  // 3. valuable coin without dollar worth (balance)
  return sortBy(
    tokens,
    // (token) => !token.default,
    (token) => {
      return (token?.worth || 0) * -1
    },
    // (token) => {
    //   const decimals = token.decimals || 18
    //   const balance = etherToWei(token.balance || '0', decimals)
    //   return parseFloat(weiToEther(balance, decimals)) * -1
    // },
  )
}

export const calculateTotalWorth = (tokens: Array<TokenInfo>) => {
  let netWorth = 0

  tokens.forEach(({ worth }) => {
    netWorth += worth || 0
  })

  return netWorth.toFixed(2)
}

export const toThousandsString = (currency: string | number) => {
  const splitNumber = currency.toString().split('.')
  let integer = splitNumber[0]
  const decimal = splitNumber[1] || ''

  let result = ''
  while (integer.length > 3) {
    result = ',' + integer.slice(-3) + result
    integer = integer.slice(0, integer.length - 3)
  }
  if (integer) {
    result = integer + result
  }

  if (decimal === '') {
    return result
  }

  return result + '.' + decimal
}
