import {
  ChainType,
  dev_api_config,
  test_api_config,
  testnet_api_config,
  mainnet_preview_api_config,
  mainnet_api_config,
  Environment,
} from '@unipasswallet/provider'
import {
  ARBITRUM_MAINNET,
  ARBITRUM_TESTNET,
  AVALANCHE_MAINNET,
  AVALANCHE_TESTNET,
  KCC_MAINNET,
  KCC_TESTNET,
  OKC_MAINNET,
  OKC_TESTNET,
  BSC_MAINNET,
  BSC_TESTNET,
  ETHEREUM_GOERLI,
  ETHEREUM_MAINNET,
  MAINNET_CHAIN_IDS,
  MAINNET_CHAINS_PAYMENT_TYPES,
  POLYGON_MAINNET,
  POLYGON_MUMBAI,
  RANGERS_MAINNET,
  RANGERS_ROBIN,
  SCROLL_TESTNET,
  PLATON_MAINNET,
  PLATON_TESTNET,
  TESTNET_CHAIN_IDS,
  TESTNET_CHAINS_PAYMENT_TYPES,
} from './constants'
import { isNumber } from '@vueuse/core'

export interface Token {
  symbol: string
  contractAddress: string
  decimals: number
}

export interface TokenInfo extends Token {
  chain: ChainType
  name: string
  chainId: number
  default?: boolean
  price?: number
  worth?: number
  inited?: boolean
  balance?: string
  logoURI?: string
}

const getSdkConfig = () => {
  const env = process.env.VUE_APP_Net
  let net: Environment = 'testnet'
  let urlConfig = undefined
  if (env === 'dev') {
    urlConfig = dev_api_config
  } else if (env === 'test') {
    urlConfig = test_api_config
  } else if (env === 'testnet') {
    urlConfig = testnet_api_config
  } else if (env === 'preview') {
    net = 'mainnet'
    urlConfig = mainnet_preview_api_config
  } else if (env === 'mainnet') {
    net = 'mainnet'
    urlConfig = mainnet_api_config
  } else {
    urlConfig = testnet_api_config
  }
  return {
    net,
    urlConfig,
  }
}

export const sdkConfig = getSdkConfig()

export const getChainName = (chain?: ChainType) => {
  if (!chain) {
    return ''
  }
  const dict = {
    bsc: 'BSC',
    polygon: 'Polygon',
    rangers: 'Rangers',
    eth: 'ETH',
    scroll: 'Scroll',
    arbitrum: 'Arbitrum',
    avalanche: 'Avalanche',
    kcc: 'KCC',
    platon: 'PlatON',
    okc: 'OKC',
  }
  return dict[chain]
}

export const getCurrentEnv = () => {
  const env = process.env.VUE_APP_Net
  let net: Environment = 'testnet'
  if (env === 'dev') {
    net = 'testnet'
  } else if (env === 'test') {
    net = 'testnet'
  } else if (env === 'testnet') {
    net = 'testnet'
  } else if (env === 'preview') {
    net = 'mainnet'
  } else if (env === 'mainnet') {
    net = 'mainnet'
  }
  return net
}

export const getAuthNodeChain = (chainType: ChainType): string => {
  const net = getCurrentEnv()

  if (net === 'testnet') {
    switch (chainType) {
      case 'eth':
        return 'eth-goerli'
      case 'polygon':
        return 'polygon-mumbai'
      case 'bsc':
        return 'bsc-testnet'
      case 'rangers':
        return 'rangers-robin'
      case 'scroll':
        return 'scroll-testnet'
      case 'arbitrum':
        return 'arbitrum-testnet'
      case 'avalanche':
        return 'avalanche-testnet'
      case 'kcc':
        return 'kcc-testnet'
      case 'platon':
        return 'platon-testnet'
      case 'okc':
        return 'okc-testnet'
      default:
        return 'polygon-mumbai'
    }
  } else if (net === 'mainnet') {
    switch (chainType) {
      case 'eth':
        return 'eth-mainnet'
      case 'polygon':
        return 'polygon-mainnet'
      case 'bsc':
        return 'bsc-mainnet'
      case 'rangers':
        return 'rangers-mainnet'
      case 'arbitrum':
        return 'arbitrum-mainnet'
      case 'avalanche':
        return 'avalanche-mainnet'
      case 'kcc':
        return 'kcc-mainnet'
      case 'platon':
        return 'platon-mainnet'
      case 'okc':
        return 'okc-mainnet'
      default:
        return 'polygon-mainnet'
    }
  }
  return 'polygon-mumbai'
}

export const getSupportedChainIds = (): number[] => {
  const net = getCurrentEnv()

  if (net === 'testnet') {
    return TESTNET_CHAIN_IDS
  } else if (net === 'mainnet') {
    return MAINNET_CHAIN_IDS
  } else return []
}

export const getDefaultChainId = (): number => {
  const net = getCurrentEnv()

  if (net === 'testnet') {
    return POLYGON_MUMBAI
  } else if (net === 'mainnet') {
    return POLYGON_MAINNET
  } else return POLYGON_MUMBAI
}

export const getDefaultChainIdForIndexPage = (): number => {
  const savedChainId = sessionStorage?.getItem('chainId')

  if (savedChainId && isNumber(parseInt(savedChainId))) return Number(savedChainId)

  return getDefaultChainId()
}

export const getChainNameByChainId = (id: number | string): ChainType => {
  switch (Number(id)) {
    case ETHEREUM_MAINNET:
    case ETHEREUM_GOERLI:
      return 'eth'

    case POLYGON_MAINNET:
    case POLYGON_MUMBAI:
      return 'polygon'

    case BSC_MAINNET:
    case BSC_TESTNET:
      return 'bsc'

    case RANGERS_MAINNET:
    case RANGERS_ROBIN:
      return 'rangers'

    case SCROLL_TESTNET:
      return 'scroll'

    case ARBITRUM_TESTNET:
    case ARBITRUM_MAINNET:
      return 'arbitrum'

    case AVALANCHE_TESTNET:
    case AVALANCHE_MAINNET:
      return 'avalanche'

    case KCC_TESTNET:
    case KCC_MAINNET:
      return 'kcc'

    case PLATON_MAINNET:
    case PLATON_TESTNET:
      return 'platon'

    case OKC_TESTNET:
    case OKC_MAINNET:
      return 'okc'

    default:
      return 'polygon'
  }
}

export const getChainScanNameByChainId = (id: number | string) => {
  switch (Number(id)) {
    case ETHEREUM_MAINNET:
    case ETHEREUM_GOERLI:
      return 'Etherscan'

    case POLYGON_MAINNET:
    case POLYGON_MUMBAI:
      return 'Polygonscan'

    case BSC_MAINNET:
    case BSC_TESTNET:
      return 'Bscscan'

    // case RANGERS_MAINNET:
    // case RANGERS_ROBIN:
    //   return 'rangers'

    // case SCROLL_TESTNET:
    //   return 'scroll'

    case ARBITRUM_TESTNET:
    case ARBITRUM_MAINNET:
      return 'Arbiscan'

    case AVALANCHE_TESTNET:
    case AVALANCHE_MAINNET:
      return 'Snowtrace'

    case KCC_TESTNET:
    case KCC_MAINNET:
      return 'KCC'

    default:
      return 'Polygonscan'
  }
}

export const getChainPaymentTypes = (id: number | string): string[] => {
  const net = getCurrentEnv()

  if (net === 'mainnet') {
    return MAINNET_CHAINS_PAYMENT_TYPES[id] || []
  } else {
    return TESTNET_CHAINS_PAYMENT_TYPES[id] || []
  }
}

export const getChainIdByChainType = (chainType: ChainType): number => {
  const net = getCurrentEnv()

  switch (chainType) {
    case 'polygon':
      return net === 'mainnet' ? POLYGON_MAINNET : POLYGON_MUMBAI
    case 'eth':
      return net === 'mainnet' ? ETHEREUM_MAINNET : ETHEREUM_GOERLI
    case 'bsc':
      return net === 'mainnet' ? BSC_MAINNET : BSC_TESTNET
    case 'rangers':
      return net === 'mainnet' ? RANGERS_MAINNET : RANGERS_ROBIN
    case 'arbitrum':
      return net === 'mainnet' ? ARBITRUM_MAINNET : ARBITRUM_TESTNET
    case 'scroll':
      // TODO add scroll mainnet chain id
      return net === 'mainnet' ? -1 : SCROLL_TESTNET
    case 'avalanche':
      return net === 'mainnet' ? AVALANCHE_MAINNET : AVALANCHE_TESTNET
    case 'kcc':
      return net === 'mainnet' ? KCC_MAINNET : KCC_TESTNET
    case 'platon':
      // TODO add platon mainnet chain id
      return net === 'mainnet' ? PLATON_MAINNET : PLATON_TESTNET
    case 'okc':
      return net === 'mainnet' ? OKC_MAINNET : OKC_TESTNET
    default:
      throw new Error(`Not Support Chain: ${chainType}`)
  }
}

export const getRpcByChainID = (chainId: number) => {
  switch (chainId) {
    case ETHEREUM_MAINNET:
    case ETHEREUM_GOERLI:
      return process.env.VUE_APP_ETH_RPC
    case POLYGON_MAINNET:
    case POLYGON_MUMBAI:
      return process.env.VUE_APP_Polygon_RPC
    case BSC_MAINNET:
    case BSC_TESTNET:
      return process.env.VUE_APP_BSC_RPC
    case RANGERS_MAINNET:
    case RANGERS_ROBIN:
      return process.env.VUE_APP_Rangers_RPC
    case SCROLL_TESTNET:
      return process.env.VUE_APP_SCROLL_RPC
    case ARBITRUM_MAINNET:
    case ARBITRUM_TESTNET:
      return process.env.VUE_APP_ARBITRUM_RPC
    case AVALANCHE_MAINNET:
    case AVALANCHE_TESTNET:
      return process.env.VUE_APP_AVALANCHE_RPC
    case KCC_MAINNET:
    case KCC_TESTNET:
      return process.env.VUE_APP_KCC_RPC
    case PLATON_MAINNET:
    case PLATON_TESTNET:
      return process.env.VUE_APP_PLATON_RPC
    case OKC_MAINNET:
    case OKC_TESTNET:
      return process.env.VUE_APP_OKC_RPC
  }

  return undefined
}

export const getNativeTokenSymbolByChainId = (chainId: number) => {
  switch (chainId) {
    case ETHEREUM_MAINNET:
    case ETHEREUM_GOERLI:
    case SCROLL_TESTNET:
    case ARBITRUM_MAINNET:
    case ARBITRUM_TESTNET:
      return 'ETH'
    case POLYGON_MAINNET:
    case POLYGON_MUMBAI:
      return 'MATIC'
    case BSC_MAINNET:
    case BSC_TESTNET:
      return 'BNB'
    case RANGERS_MAINNET:
    case RANGERS_ROBIN:
      return 'RPG'
    case AVALANCHE_MAINNET:
    case AVALANCHE_TESTNET:
      return 'AVAX'
    case KCC_MAINNET:
    case KCC_TESTNET:
      return 'KCS'
    case PLATON_MAINNET:
    case PLATON_TESTNET:
      return 'LAT'
    case OKC_MAINNET:
    case OKC_TESTNET:
      return 'OKT'
    default:
      return 'MATIC'
  }
}

export const supportedChainListInfo = getSupportedChainIds().map((chainId) => {
  return {
    label: getChainName(getChainNameByChainId(chainId)),
    value: chainId.toString(),
  }
})

export const getStackUpUrlByChainType = (chainType: ChainType) => {
  // eth and polygon only now
  const net = getCurrentEnv()

  if (net === 'testnet') {
    switch (chainType) {
      case 'eth':
        return 'https://bundler.wallet.unipass.id/eth-goerli/'
      case 'polygon':
        return 'https://bundler.wallet.unipass.id/polygon-mumbai/'
    }
  } else if (net === 'mainnet') {
    switch (chainType) {
      case 'eth':
        return 'https://bundler.wallet.unipass.id/eth-mainnet/'
      case 'polygon':
        return 'https://bundler.wallet.unipass.id/polygon-mainnet/'
    }
  }
  return 'https://bundler.wallet.unipass.id/eth-goerli/'
}
