type TaskFunc = () => void

export class PollingTask {
  _timer?: NodeJS.Timeout | null
  private _task: TaskFunc
  private _interval: number

  constructor(task: TaskFunc, interval = 1000) {
    this._task = task
    this._interval = interval
  }

  public start() {
    if (!this._timer) {
      this._task()
      this._timer = setInterval(this._task, this._interval)
    }
  }

  public stop() {
    if (this._timer) clearInterval(this._timer)
    this._timer = null
  }

  public once() {
    this._task()
  }
}
