export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'
export const multicallAddress = '0x175d02d277eac0838af14D09bf59f11B365BAB42'
export const HookEIP4337 = '0xD46c1a3D33ab419F58a524a880f6BAc0E17553e5'
export const EIP4337EntryPointAddress = '0x0576a174D229E3cFA37253523E645A78A0C91B57'
export const paymasterUrl =
  process.env.VUE_APP_UniPass_Paymaster || 'https://testnet.wallet.unipass.id/paymaster/'

// supported chain ids, do not change
// ethereum
export const ETHEREUM_MAINNET = 1
export const ETHEREUM_GOERLI = 5

// polygon
export const POLYGON_MAINNET = 137
export const POLYGON_MUMBAI = 80001

// bsc
export const BSC_MAINNET = 56
export const BSC_TESTNET = 97

// rangers
export const RANGERS_MAINNET = 2025
export const RANGERS_ROBIN = 9527

// scroll testnet only
export const SCROLL_TESTNET = 534353

// arbitrum
export const ARBITRUM_TESTNET = 421613
export const ARBITRUM_MAINNET = 42161

// avax
export const AVALANCHE_TESTNET = 43113
export const AVALANCHE_MAINNET = 43114

// kcc
export const KCC_TESTNET = 322
export const KCC_MAINNET = 321

// okc
export const OKC_TESTNET = 65
export const OKC_MAINNET = 66

// platon
export const PLATON_TESTNET = 2206132
export const PLATON_MAINNET = 210425

export const TESTNET_CHAINS_PAYMENT_TYPES = {
  [ETHEREUM_GOERLI]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [POLYGON_MUMBAI]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [BSC_TESTNET]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [RANGERS_ROBIN]: ['alchemyPay'],
  [ARBITRUM_TESTNET]: ['alchemyPay', 'binanceConnect'],
  [SCROLL_TESTNET]: [],
  [AVALANCHE_TESTNET]: [],
  [KCC_TESTNET]: [],
  [PLATON_TESTNET]: [],
  [OKC_TESTNET]: [],
}

export const MAINNET_CHAINS_PAYMENT_TYPES = {
  [ETHEREUM_MAINNET]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [POLYGON_MAINNET]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [BSC_MAINNET]: ['alchemyPay', 'fatPay', 'binanceConnect'],
  [RANGERS_MAINNET]: ['alchemyPay'],
  [ARBITRUM_MAINNET]: ['alchemyPay', 'binanceConnect'],
  [AVALANCHE_MAINNET]: [],
  [KCC_MAINNET]: [],
  [PLATON_MAINNET]: [],
  [OKC_MAINNET]: [],
}

// DO NOT change the order
export const TESTNET_CHAIN_IDS = [
  ETHEREUM_GOERLI,
  BSC_TESTNET,
  POLYGON_MUMBAI,
  ARBITRUM_TESTNET,
  AVALANCHE_TESTNET,
  SCROLL_TESTNET,
  OKC_TESTNET,
  KCC_TESTNET,
  PLATON_TESTNET,
  RANGERS_ROBIN,
]

// DO NOT change the order
export const MAINNET_CHAIN_IDS = [
  ETHEREUM_MAINNET,
  BSC_MAINNET,
  POLYGON_MAINNET,
  ARBITRUM_MAINNET,
  AVALANCHE_MAINNET,
  // SCROLL_MAINNET,
  OKC_MAINNET,
  KCC_MAINNET,
  PLATON_MAINNET,
  RANGERS_MAINNET,
]

// NFT test net
export const TESTNET_NFT_CHAIN_IDS = [ETHEREUM_GOERLI, POLYGON_MUMBAI, ARBITRUM_TESTNET]

// NFT main net
export const MAINNET_NFT_CHAIN_IDS = [
  ETHEREUM_MAINNET,
  POLYGON_MAINNET,
  BSC_MAINNET,
  ARBITRUM_MAINNET,
]
