import dayjs from 'dayjs'
import jwt_decode from 'jwt-decode'
import { genGoogleOAuthUrl } from './google-oauth'
import { useUserStore } from '@/store/user'
import { getOAuthUserInfo, LocalStorageService } from '@/store/storages'
import { useOAuthLoginStore } from '@/store/oauth-login'
import { useChainAccountStore } from '@/store/chain-account'
import { checkNeedOAuth } from './index'
import { ElMessageBox } from 'element-plus'
import i18n from '@/plugins/i18n'
import { cachePathForOAuth, getCachedOAuthPath } from '@/service/check-environment'
import router from '@/plugins/router'

export type OAuthCallBack = () => Promise<void>

export const checkAuthorization = () => {
  const authorization = getAuthorization()
  let needOAuth = false
  if (authorization) {
    const decoded = jwt_decode(authorization) as any
    if (dayjs(decoded.exp * 1000).isBefore(dayjs())) needOAuth = true
  } else {
    needOAuth = true
  }
  return needOAuth
}

export const getAuthorization = () => {
  return getOAuthUserInfo()?.authorization
}

export const handleOAuthForAuthorizationExpired = async () => {
  LocalStorageService.set('AUTHORIZATION_REQUIRE_STATE', true)
  const { accountInfo } = useUserStore()
  const { email, oauth_provider } = accountInfo
  if (oauth_provider === 0) {
    // google oauth
    window.location.href = genGoogleOAuthUrl(email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: 'login',
      email,
      provider: oauth_provider,
    })
  }
}

export const checkAuthorizationExpired = async (path?: string) => {
  const needAuth = checkNeedOAuth('OAuth')
  if (!needAuth) return
  const { t: $t } = i18n.global
  const userStore = useUserStore()
  await ElMessageBox.alert($t('ExpiredTips'), $t('ExpiredTitle'), {
    confirmButtonText: $t('Confirm'),
    center: true,
    showCancelButton: true,
    cancelButtonText: $t('LogOut'),
    showClose: false,
    async beforeClose(action, instance, done) {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true
        // if no cached oauth path, set '/'; otherwise, do nothing
        cachePathForOAuth(getCachedOAuthPath())
        handleOAuthForAuthorizationExpired()
      } else {
        userStore.exit()
        done()
      }
    },
  })
}

export const checkKeysetHash = async () => {
  const chainAccountStore = useChainAccountStore()
  const userStore = useUserStore()
  await userStore.init()
  const { address, keyset } = userStore.accountInfo
  await chainAccountStore.fetchAccountInfo(address, true, keyset.hash)
  if (keyset.hash !== chainAccountStore.keysetHash) {
    userStore.exit()
  }
}

export const handleRedirectEnvAfterOAuth = async () => {
  const OAuthPath = getCachedOAuthPath()
  await router.replace({
    path: OAuthPath,
    query: {
      forceLogin: '0',
    },
  })
}
