// import { useUserStore } from '@/store/user'
import { UPMessage } from '@unipasswallet/popup-types'

export const connectReady = (cb: (value: UPMessage) => void) => {
  if (!window?.flutter_inappwebview?.callHandler) return
  // await initFlutterWebView()
  window.onConnectPageReady = (connectValue: UPMessage) => {
    try {
      cb(connectValue)
    } catch (err) {
      console.error('connectReady:', err)
    }
  }
  window.flutter_inappwebview.callHandler('onConnectReady', true)
}

export const signMessageReady = (cb: (value: UPMessage) => void) => {
  if (!window?.flutter_inappwebview?.callHandler) return
  // await initFlutterWebView()
  window.onSignMessageReady = (signMessageValue: UPMessage) => {
    try {
      cb(signMessageValue)
    } catch (err) {
      console.error('signMessageReady:', err)
    }
  }
  window.flutter_inappwebview.callHandler('onSignMessageReady', true)
}

export const sendTransactionReady = (cb: (value: UPMessage) => void) => {
  if (!window?.flutter_inappwebview?.callHandler) return
  // await initFlutterWebView()
  window.onSendTransactionReady = (sendTransactionValue: UPMessage) => {
    try {
      cb(sendTransactionValue)
    } catch (err) {
      console.error('sendTransactionReady:', err)
    }
  }
  window.flutter_inappwebview.callHandler('onSendTransactionReady', true)
}
