import { isArray } from 'lodash-es'
import {
  combineTokens,
  calculateTotalWorth,
  sortValuableTokens,
  filterRemovedAddress,
} from './utils'
import {
  TokenInfo,
  getChainNameByChainId,
  getCurrentEnv,
  getNativeTokenSymbolByChainId,
} from '@/service/chains-config'
import testnetTokens from './tokens-testnet'
import mainnetTokens from './tokens-mainnet'
import api from '@/service/backend'
import { getBalancesByMulticall } from './multicall-fetch-asset'
import { ADDRESS_ZERO } from '@/service/constants'
import { etherToWei, weiToEther } from '@/service/format-bignumber'
import { LocalStorageService } from '@/store/storages'

const genLocalTokensAddedKey = (chainId: number, address: string) => {
  return `local::tokens::added::${chainId}::${address}`
}

const genLocalTokensRemovedKey = (chainId: number, address: string) => {
  return `local::tokens::removed::${chainId}::${address}`
}

export const setLocalAddedTokens = (chainId: number, address: string, tokens: Array<TokenInfo>) => {
  LocalStorageService.setDirectly(genLocalTokensAddedKey(chainId, address), tokens)
}

export const setLocalRemovedTokens = (
  chainId: number,
  address: string,
  addresses: Array<string>,
) => {
  LocalStorageService.setDirectly(genLocalTokensRemovedKey(chainId, address), addresses)
}

export const getLocalAddedTokens = (chainId: number, address: string): Array<TokenInfo> => {
  try {
    const value = LocalStorageService.getDirectly(genLocalTokensAddedKey(chainId, address))
    if (!value) return []
    const localTokens = JSON.parse(value) as Array<TokenInfo>

    if (isArray(localTokens)) {
      return localTokens
    }
    return []
  } catch {
    return []
  }
}

export const getLocalRemovedTokens = (chainId: number, address: string): Array<string> => {
  try {
    const value = LocalStorageService.getDirectly(genLocalTokensRemovedKey(chainId, address))
    if (!value) return []
    const removedAddresses = JSON.parse(value) as Array<string>

    if (isArray(removedAddresses)) {
      return removedAddresses
    }
    return []
  } catch {
    return []
  }
}

export const getDefaultTokens = (chainId: number, address: string): Array<TokenInfo> => {
  const env = getCurrentEnv()
  const chain = getChainNameByChainId(chainId)

  const nativeToken: TokenInfo = {
    chain,
    chainId,
    name: getNativeTokenSymbolByChainId(chainId),
    symbol: getNativeTokenSymbolByChainId(chainId),
    decimals: 18,
    contractAddress: ADDRESS_ZERO,
  }
  let defaultTokens = []
  if (env === 'mainnet') {
    defaultTokens = mainnetTokens[chain] ?? []
  } else {
    defaultTokens = testnetTokens[chain] ?? []
  }

  const tokens = [nativeToken, ...defaultTokens].map((token: any) => {
    return { ...token, chain, default: true }
  })
  const removedTokens = getLocalRemovedTokens(chainId, address)

  return filterRemovedAddress(tokens, removedTokens)
}

export const getCustomTokens = (chainId: number, address: string): Array<TokenInfo> => {
  const defaultTokens = getDefaultTokens(chainId, address)

  const addedTokens = getLocalAddedTokens(chainId, address)

  if (addedTokens.length === 0) {
    // set local tokens
    setLocalAddedTokens(chainId, address, defaultTokens)
    return defaultTokens
  }

  const nativeToken = addedTokens.find((token) => token.contractAddress === ADDRESS_ZERO)

  if (!nativeToken) {
    const chain = getChainNameByChainId(chainId)

    const native: TokenInfo = {
      chain,
      chainId,
      name: getNativeTokenSymbolByChainId(chainId),
      symbol: getNativeTokenSymbolByChainId(chainId),
      decimals: 18,
      contractAddress: ADDRESS_ZERO,
      default: true,
    }
    return [native, ...addedTokens]
  }

  return addedTokens
}

export const getValuableTokens = async (
  chainId: number,
  address: string,
): Promise<{ sortedTokens: Array<TokenInfo>; netWorth: string; prevChainId: number }> => {
  let tokens = getCustomTokens(chainId, address)
  const tokenBalance = await api.getTokenBalances(chainId, address)
  const removedTokens = getLocalRemovedTokens(chainId, address)

  if (tokenBalance.ok) {
    tokens = filterRemovedAddress(combineTokens(tokenBalance.data, tokens), removedTokens)
  }
  tokens = await getBalancesByMulticall(address, chainId, tokens)

  const addresses = tokens.map((token) => token.contractAddress)
  const tokenPrices = await api.getTokenPrices(chainId, addresses)

  if (tokenPrices.ok) {
    tokens = combineTokens(tokens, tokenPrices.data.tokens)
  }

  const initializedTokens = tokens.map((token) => {
    const decimals = token.decimals || 18
    const balance = etherToWei(token.balance || '0', decimals)
    const price = etherToWei((token.price || 0).toFixed(18), 18)
    const worth = parseFloat(weiToEther(balance.mul(price), 18 + decimals))
    return {
      ...token,
      inited: true,
      chain: getChainNameByChainId(chainId),
      worth,
      logoURI: token.default ? '' : token.logoURI,
    }
  })

  const sortedTokens = sortValuableTokens(initializedTokens)
  return { sortedTokens, netWorth: calculateTotalWorth(sortedTokens), prevChainId: chainId }
}
