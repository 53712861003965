import { useUserStore } from '@/store/user'
import { useSignStore } from '@/store/sign'
import i18n from '@/plugins/i18n'
import { getAuthNodeChain } from '@/service/chains-config'
import router from '@/plugins/router'
import { SyncStatusEnum } from '@/service//enum'
import { upError } from '@/utils/useUniPass'
import { checkAuthorization } from './check-authorization'
import { checkUpSignToken } from './check-up-sign-token'
import api from '@/service/backend'
import { ElMessageBox } from 'element-plus'
import type { ChainType } from '@unipasswallet/provider'

export type OAuthCallBack = () => Promise<void>
export type ShowOAuthCallbackFunc = () => void

export const checkNeedOAuth = (OAuthType: 'UP_SIGN_TOKEN' | 'OAuth') => {
  return OAuthType === 'OAuth' ? checkAuthorization() : checkUpSignToken()
}

export const showOAuthConfirm = (cb: OAuthCallBack) => {
  const userStore = useUserStore()
  userStore.showOAuthCallback = cb
  userStore.showOAuth = true
}

export const isAccountSyncedOnChain = async (chain: ChainType) => {
  if (chain === 'polygon') return true
  const { t: $t } = i18n.global
  const userStore = useUserStore()
  const signStore = useSignStore()
  try {
    const {
      data: { syncStatus },
    } = await api.getSyncStatus({
      email: userStore.accountInfo.email,
      authChainNode: getAuthNodeChain(chain),
    })
    if (syncStatus !== SyncStatusEnum.Synced) {
      const tip = syncStatus === SyncStatusEnum.NotSynced ? $t('SyncTip') : $t('DeployTip')
      ElMessageBox.alert(tip, $t('Notification'), {
        confirmButtonText: $t('Confirm'),
        center: true,
        showClose: false,
      })
        .then(() => {
          signStore.fromWalletConnectSign = true
          signStore.fromStandardProviderSign = true
          signStore.initTransactionData(
            { chain: chain as any },
            {
              from: userStore.accountInfo.address,
              to: userStore.accountInfo.address,
              value: '0x',
              data: '0x',
            },
          )
          router.push('/async-account')
        })
        .catch()
      return false
    }
    return true
  } catch (err) {
    upError('failed to fetch account status')
    return false
  }
}
