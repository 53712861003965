import { LocalStorageService } from '@/store/storages'
import { OpenType } from '@/utils/useUniPass'

export const isFlutterEnv = () => {
  return !!window?.flutter_inappwebview?.callHandler
}

export const isPopupEnv = () => {
  return !!window.opener || window.name === 'UNIPASS_IFRAME'
}

export const isUnityEnv = () => {
  return !!window.vuplex
}

export const isUnRealEnv = () => {
  return !!window?.ue?.unipass
}

/**
 * If redirectUrl exists in redirectEnv, return redirectUrl. If not, return false
 */
export const isRedirectEnv = () => {
  const url = new URL(LocalStorageService.get('REDIRECT_URL') || window.location.href)
  const redirectUrl = url.searchParams.get('redirectUrl')
  return !!redirectUrl && url
}

export const clearCachedRedirectUrl = () => {
  LocalStorageService.remove('REDIRECT_URL')
}

export const cachePathForOAuth = (path: string) => {
  LocalStorageService.set('OAUTH_REDIRECT', path)
  window.document.referrer && LocalStorageService.set('OAUTH_REFERRER', window.document.referrer)
}

export const getCachedOAuthPath = (defaultPath = '/') => {
  const path = LocalStorageService.get('OAUTH_REDIRECT') || defaultPath
  // only can use once
  LocalStorageService.remove('OAUTH_REDIRECT')
  return path
}

export const getOpenType = (): OpenType => {
  if (isPopupEnv()) return 'popup'
  if (isFlutterEnv()) return 'flutter'
  if (isUnityEnv()) return 'unity'
  if (isUnRealEnv() || !!window?.ue) return 'unreal'
  if (isRedirectEnv()) return 'redirect'
  return 'origin'
}

export const isIframeEnv = () => {
  return window.name === 'UNIPASS_IFRAME'
}
