import { getChainNameByChainId, getDefaultChainIdForIndexPage } from '@/service/chains-config';
import { useUserStore } from '@/store/user';
import { PollingTask } from '@/utils/polling';
import { useNftStore } from '@/store/nft';
import { getDefaultTokens, getValuableTokens, getLocalRemovedTokens, setLocalRemovedTokens, getLocalAddedTokens, setLocalAddedTokens } from '@/utils/account-token';
import { toThousandsString } from '@/utils/account-token/utils';
import api from '@/service/backend';
import { ADDRESS_ZERO } from '@/service/constants';
import { isSameAddress } from '@/utils/string-utils';
export const useCoinStore = defineStore({
  id: 'coinStore',
  state: () => {
    const userStore = useUserStore();
    return {
      coins: getDefaultTokens(getDefaultChainIdForIndexPage(), userStore.accountInfo.address),
      pollingTask: undefined,
      active: -1,
      showLumpSum: true,
      netWorth: '-',
      apBalance: 0,
      tokenLists: {
        eth: [],
        polygon: [],
        bsc: [],
        rangers: [],
        scroll: [],
        arbitrum: [],
        avalanche: [],
        kcc: [],
        platon: [],
        okc: []
      }
    };
  },
  actions: {
    start() {
      if (!this.pollingTask) {
        const task = new PollingTask(this._netWorthTask, 20000);
        task.start();
        this.pollingTask = task;
      } else {
        this.pollingTask.start();
      }
    },

    stop() {
      if (this.pollingTask) {
        this.pollingTask.stop();
      }
    },

    async _netWorthTask() {
      const userStore = useUserStore();

      if (userStore.accountInfo.address) {
        const isSameHash = await userStore.checkKeysetHash();

        if (isSameHash) {
          await this.fetchBalances();
        }
      }
    },

    async fetchBalances() {
      const userStore = useUserStore();
      const nftStore = useNftStore();
      const address = userStore.accountInfo.address;

      if (address) {
        // tokens
        this.getAccountAssets(address); // nft

        nftStore.loadCollection(address); // ap

        this.fetchApBalance();
      }
    },

    async fetchApBalance() {
      try {
        var _res$data;

        const res = await api.fetchApBalance();

        if (res !== null && res !== void 0 && (_res$data = res.data) !== null && _res$data !== void 0 && _res$data.availActionPoint) {
          this.apBalance = res.data.availActionPoint;
        }
      } catch (e) {
        console.error('fetch AP balance failed', e);
      }
    },

    async getAccountAssets(address) {
      var _sessionStorage;

      const chainId = useUserStore().chainId;
      const chainType = getChainNameByChainId(chainId);

      const _address = ((_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 ? void 0 : _sessionStorage.getItem('mockAddress')) || address;

      const {
        sortedTokens,
        netWorth,
        prevChainId
      } = await getValuableTokens(chainId, _address);

      if (prevChainId === useUserStore().chainId) {
        this.coins = sortedTokens;
        this.netWorth = toThousandsString(netWorth);
      } // add tokens list


      if (this.tokenLists[chainType].length === 0) {
        const res = await api.getTokenList(chainId);
        const data = res.ok ? res.data : [];
        this.tokenLists[chainType] = data.filter(token => token.contractAddress !== ADDRESS_ZERO).map(token => {
          const added = !!this.coins.find(addedTokens => {
            return isSameAddress(token.contractAddress, addedTokens.contractAddress);
          });
          return { ...token,
            added,
            loading: false,
            chain: chainType
          };
        });
      }
    },

    async removeTokenFromTokenList(chainId, contractAddress) {
      const chainType = getChainNameByChainId(chainId);
      const userStore = useUserStore();
      const userAddress = userStore.accountInfo.address;
      const removedAddress = getLocalRemovedTokens(chainId, userAddress);

      if (!removedAddress.includes(contractAddress)) {
        setLocalRemovedTokens(chainId, userAddress, [...removedAddress, contractAddress]);
      }

      this.tokenLists[chainType] = this.tokenLists[chainType].map(token => {
        if (isSameAddress(token.contractAddress, contractAddress)) {
          return { ...token,
            added: false
          };
        }

        return token;
      });
      await this.getAccountAssets(userAddress);
    },

    async addTokenToTokenList(chainId, tokenToAdd) {
      const chainType = getChainNameByChainId(chainId);
      const userStore = useUserStore();
      const userAddress = userStore.accountInfo.address; // remove form local removed tokens

      const removedAddress = getLocalRemovedTokens(chainId, userAddress).filter(address => !isSameAddress(address, tokenToAdd.contractAddress));
      setLocalRemovedTokens(chainId, userAddress, removedAddress); // add to local added tokens

      const addedTokens = getLocalAddedTokens(chainId, userAddress);
      const existing = addedTokens.find(token => isSameAddress(token.contractAddress, tokenToAdd.contractAddress));

      if (!existing) {
        setLocalAddedTokens(chainId, userAddress, [...addedTokens, tokenToAdd]);
      }

      this.tokenLists[chainType] = this.tokenLists[chainType].map(token => {
        if (isSameAddress(token.contractAddress, tokenToAdd.contractAddress)) {
          return { ...token,
            added: true
          };
        }

        return token;
      });
      await this.getAccountAssets(userAddress);
    }

  }
});