import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.error.cause.js";
import { isSameAddress } from './../utils/string-utils';
import { getAuthNodeChain, getChainIdByChainType, getCurrentEnv } from '@/service/chains-config';
import jwt_decode from 'jwt-decode';
import { upTip } from '@/utils/useUniPass';
import { useUserStore } from '@/store/user';
import { BigNumber, Contract, utils } from 'ethers';
import { checkStatusForSendTransaction, handleOAuthForSendTransaction, handleOAuthForSignMessage, updateUpSignToken } from '@/utils/oauth/check-up-sign-token';
import { initTheme } from '@/utils/init-theme';
import { useCoinStore } from '@/store/coin';
import { analyzeTransactionData, generateSimulateTransaction, generateTransaction } from '@/service/tx-data-analyzer';
import api from '@/service/backend';
import { getSigSize, isAddEIP4337Hook } from '@unipasswallet/eip4337-hook/dist/utils';
import { ModuleHookEIP4337WalletInterface } from '@unipasswallet/utils';
import { ADDRESS_ZERO, EIP4337EntryPointAddress, HookEIP4337, paymasterUrl } from '@/service/constants';
import { UnipassAccountAPI } from '@unipasswallet/eip4337-hook/dist/unipassAccountApi';
import { VerifyingPaymasterAPI } from '@unipasswallet/eip4337-hook/dist/verifyingPaymasterAPI';
import { CallType } from '@unipasswallet/transactions';
import { Interface } from 'ethers/lib/utils';
import { SignMessageType, SyncStatusEnum } from '@/service/enum';
import { getDefaultTokens } from '@/utils/account-token';
import { LocalStorageService } from './storages';
export const useSignStore = defineStore({
  id: 'signStore',
  state: () => {
    return {
      cards: [],
      feeOptions: [],
      eip4337FeeOptions: [],
      eip4337UserOperationStruct: undefined,
      gasMod: 'normal',
      isFeeRequired: true,
      isEIP4337FeeRequired: true,
      feeSymbol: '',
      eip4337FeeSymbol: '',
      loading: false,
      appIcon: '',
      appName: '',
      returnEmail: false,
      referrer: '',
      starkKeyMessage: '',
      sessionKeyAddress: '',
      connectLoading: false,
      connectAndAuth: {
        needAuthorize: false,
        message: '',
        showMessage: false
      },
      chain: 'polygon',
      symbol: 'MATIC',
      coinAddress: ADDRESS_ZERO,
      isEIP4337HookTx: false,
      isAccountSynced: true,
      fromThirdParty: false,
      isOpenEIP4337Hook: false,
      transaction: {
        from: '',
        data: '',
        to: '',
        value: ''
      },
      gasFeeLoading: true,
      signMassage: {
        loading: false,
        type: SignMessageType.v1,
        typedData: {},
        msg: '',
        from: '',
        referrer: ''
      },
      walletConnectId: undefined,
      walletConnectTopic: '',
      fromWalletConnectSign: false,
      fromEIP4337Sign: false,
      checkAccountStatusLoading: false,
      fromStandardProviderSign: false,
      nft: {
        imageUrl: '',
        chainId: '',
        contractAddress: '',
        name: '',
        tokenId: '',
        amount: ''
      }
    };
  },
  getters: {
    coin(state) {
      const chain = state.chain;
      const symbol = state.symbol;
      const contractAddress = state.coinAddress;

      const _coin = useCoinStore().coins.find(e => e.chain === chain && e.symbol === symbol && isSameAddress(e.contractAddress, contractAddress));

      return _coin;
    }

  },
  actions: {
    async restoreSignState(signState, idToken, type) {
      try {
        const state = JSON.parse(signState);
        state.feeSymbol = '';
        this.$state = state;

        if (type === 'signMessage') {
          await updateUpSignToken(idToken, () => handleOAuthForSignMessage(signState));
        } else {
          const {
            nonce
          } = jwt_decode(idToken);

          if (nonce.startsWith('update-up-sign-token')) {
            await updateUpSignToken(idToken, () => handleOAuthForSendTransaction('upSignToken', signState));
          } else {
            await updateUpSignToken(idToken, () => handleOAuthForSendTransaction('multiSync', signState));
          }
        }
      } catch (e) {
        return;
      }
    },

    async initTransactionData(appSetting, payload, isEIP4337HookTx = false, fromThirdParty = false) {
      this.feeOptions = [];
      this.isFeeRequired = true;
      this.isEIP4337HookTx = isEIP4337HookTx;
      this.fromThirdParty = fromThirdParty;
      this.initAppSetting(appSetting);
      const transactionCards = await analyzeTransactionData(payload, appSetting.chain);
      if (!transactionCards) return;
      this.transaction = payload;
      this.cards = transactionCards;
      this.updateGasFee();
    },

    initAppSetting(appSetting) {
      const userStore = useUserStore();
      const coinStore = useCoinStore();

      if (appSetting) {
        const {
          theme,
          chain,
          appName,
          appIcon,
          distinctId
        } = appSetting;

        if (!sessionStorage.theme && theme) {
          sessionStorage.theme = theme;
          userStore.theme = theme;
          initTheme();
        }

        if (chain) {
          this.chain = chain;

          if (coinStore.coins.length === 0 || getChainIdByChainType(chain) !== userStore.chainId) {
            coinStore.coins = getDefaultTokens(getChainIdByChainType(chain), userStore.accountInfo.address);
          }

          userStore.chainId = getChainIdByChainType(chain);
        }

        if (distinctId) {
          var _sessionStorage;

          (_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 ? void 0 : _sessionStorage.setItem('__distinctId', distinctId);
        }

        LocalStorageService.set('UP_CONNECT_SOURCE', appName !== null && appName !== void 0 ? appName : '');
        this.appName = appName !== null && appName !== void 0 ? appName : '';

        if (appIcon) {
          this.appIcon = appIcon;
        }
      }
    },

    updateSymbolAndChain(chain, symbol, contractAddress) {
      this.symbol = symbol || this.symbol || '';
      this.chain = chain;
      this.coinAddress = contractAddress || this.coinAddress;
    },

    async updateGasFee() {
      try {
        this.feeOptions = [];
        this.eip4337FeeOptions = [];
        this.feeSymbol = '';
        this.eip4337FeeSymbol = '';
        this.gasMod = 'normal';
        this.gasFeeLoading = true;
        await this._updateGasFee();
      } catch (e) {
        this.feeOptions = [];
        console.error(`Loading Gas Failed:`);
        console.error(e);
        upTip(`Transaction Error: ${e.message || 'unknown error'}`, 0, true);
      } finally {
        this.gasFeeLoading = false;
      }
    },

    async _updateGasFee() {
      const coinStore = useCoinStore();
      const userStore = useUserStore();
      await userStore.checkKeysetHash();
      userStore.chainId = getChainIdByChainType(this.chain);
      coinStore.getAccountAssets(userStore.accountInfo.address);
      coinStore.fetchApBalance();
      const {
        data: {
          syncStatus
        }
      } = await api.getSyncStatus({
        email: userStore.accountInfo.email,
        authChainNode: getAuthNodeChain(this.chain)
      });

      if (syncStatus === SyncStatusEnum.NotReceived || syncStatus === SyncStatusEnum.NotSynced) {
        checkStatusForSendTransaction('multiSync', JSON.stringify({ ...toRaw(this.$state),
          loading: false
        }));
        return;
      }

      this.isAccountSynced = syncStatus === SyncStatusEnum.Synced;
      await Promise.all([this._simulateTransaction(), this._simulateEIP4337Transaction()]);
    },

    async isEIP4337HookAdded() {
      if (this.chain === 'eth' || this.chain === 'polygon') {
        const userStore = useUserStore();
        const provider = (await userStore.unipassWallet.wallet(this.chain)).provider;
        return await isAddEIP4337Hook(userStore.accountInfo.address, provider, HookEIP4337);
      } else {
        return false;
      }
    },

    async _simulateTransaction() {
      const userStore = useUserStore();
      const coinStore = useCoinStore();
      const transaction = generateSimulateTransaction();

      if (!transaction) {
        throw new Error('Expected Transaction For Simulating Transaction');
      }

      const simulateTx = await userStore.unipassWallet.simulateTransactions(transaction);
      if (!simulateTx) return;
      const {
        isFeeRequired,
        feeTokens,
        feeReceiver,
        discount,
        gasPrice,
        feeActionPoint
      } = simulateTx;
      this.isFeeRequired = isFeeRequired;

      if (isFeeRequired) {
        const _feeOptions = [];
        feeTokens.forEach(({
          token,
          gasUsed,
          nativeTokenPrice,
          tokenPrice
        }) => {
          const coinToken = coinStore.coins.find(x => x.chain === this.chain && x.contractAddress.toLowerCase() === token.toLowerCase()); // TODO: show fee tokens not in useStore.coins

          if (coinToken) {
            let gasFee;
            let transFee;

            if (this.chain === 'rangers') {
              gasFee = BigNumber.from(1000000);
              transFee = utils.parseEther('0.0001');
            } else {
              gasFee = BigNumber.from(gasUsed);
              transFee = gasFee.mul(gasPrice);
            }

            const amount = transFee.mul(Math.ceil(nativeTokenPrice * 10 ** 8)).div(Math.ceil(tokenPrice * 10 ** 8)).mul(discount).div(100).div(10 ** (18 - coinToken.decimals));

            _feeOptions.push({
              to: feeReceiver,
              gasLimit: gasFee,
              token: coinToken,
              amount: amount.toHexString()
            });
          }
        });
        this.feeOptions = _feeOptions;
        this.feeActionPoint = feeActionPoint;
      }
    },

    async _simulateEIP4337Transaction() {
      // EIP4337 tx not need 4337 mode gas fee
      if (this.isEIP4337HookTx || !this.isAccountSynced || this.fromThirdParty) {
        this.isOpenEIP4337Hook = false;
        return;
      } // check if the 4337 mode was opened


      if (this.chain === 'eth' || getCurrentEnv() === 'mainnet' && this.chain === 'polygon') {
        const userStore = useUserStore();
        const provider = (await userStore.unipassWallet.wallet(this.chain)).provider;
        const isOpenEIP4337Hook = await isAddEIP4337Hook(userStore.accountInfo.address, provider, HookEIP4337); // const isNeedAddEIP4337Hook = await needAddEIP4337Hook({
        //   userAddr: userStore.accountInfo.address,
        //   provider: provider!,
        //   impl: HookEIP4337,
        //   chain: getChainIdByChainType(this.chain),
        //   fetch: fetch,
        //   paymasterUrl,
        // })

        this.isOpenEIP4337Hook = isOpenEIP4337Hook;

        if (isOpenEIP4337Hook) {
          const coinStore = useCoinStore();
          const userStore = useUserStore();
          const transaction = generateTransaction();

          if (!(transaction !== null && transaction !== void 0 && transaction.tx)) {
            throw new Error('Expected Transaction For Simulating EIP4337 Transaction');
          }

          const wallet = await userStore.unipassWallet.wallet(this.chain);
          const walletAPI = new UnipassAccountAPI({
            provider: provider,
            entryPointAddress: EIP4337EntryPointAddress,
            accountAddress: userStore.accountInfo.address,
            wallet,
            paymasterAPI: new VerifyingPaymasterAPI(getChainIdByChainType(this.chain), getSigSize(wallet.keyset, [0]), paymasterUrl, EIP4337EntryPointAddress),
            overheads: {
              sigSize: getSigSize(wallet.keyset, [0])
            }
          });
          const userOperation = await this._simulateEIP4337HookTransaction(walletAPI, { ...transaction.tx,
            _isUnipassWalletTransaction: true,
            callType: CallType.Call,
            revertOnError: true,
            gasLimit: transaction.tx.gasLimit || BigNumber.from(0),
            data: transaction.tx.data || '0x'
          });
          this.eip4337UserOperationStruct = userOperation;
          this.gasMod = '4337'; // if paymasterAndData is valid, eip4337 is free

          const needPayForEIP4337Gas = userOperation.paymasterAndData == null || userOperation.paymasterAndData === '0x';

          if (needPayForEIP4337Gas) {
            this.isEIP4337FeeRequired = true;
            const coinToken = coinStore.coins.find(x => x.chain === this.chain && x.contractAddress.toLowerCase() === ADDRESS_ZERO);
            const entryPointBalance = await new Contract(EIP4337EntryPointAddress, new Interface(['function balanceOf(address) returns (uint256)']), provider).callStatic.balanceOf(userStore.accountInfo.address);
            let gasLimit = (await userOperation.callGasLimit).add(await userOperation.verificationGasLimit).add(await userOperation.preVerificationGas).sub(entryPointBalance.div(await userOperation.maxFeePerGas));

            if (gasLimit.lt(0)) {
              gasLimit = BigNumber.from(0);
            }

            this.eip4337FeeOptions = [{
              to: EIP4337EntryPointAddress,
              gasLimit,
              token: coinToken,
              amount: gasLimit.mul(userOperation.maxFeePerGas).toHexString()
            }];
          } else {
            this.eip4337FeeOptions = [];
            this.isEIP4337FeeRequired = false;
          }
        } else {
          this.eip4337UserOperationStruct = undefined;
          return;
        }
      } else {
        this.isOpenEIP4337Hook = false;
        return;
      }
    },

    async _simulateEIP4337HookTransaction(accountApi, transaction) {
      const data = ModuleHookEIP4337WalletInterface.encodeFunctionData('execFromEntryPoint', [transaction]);
      return await accountApi.createUnsignedUserOp({
        target: await accountApi.getAccountAddress(),
        data
      });
    }

  }
});