import { useUserStore } from '@/store/user'
import api from '@/service/backend'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import { useOAuthLoginStore } from '@/store/oauth-login'
import { getOAuthUserInfo, LocalStorageService } from '@/store/storages'
import { genGoogleOAuthSignUrl, randomString } from './google-oauth'
import blockchain from '@/service/blockchain'
import { IdTokenParams } from './parse-hash'
import { handleWrongAccount } from './wrong-oauth'
import { genUnipassWalletContext } from '@/service/blockchain'
import { SyncAccountTxBuilder } from '@unipasswallet/transaction-builders'
import { useChainAccountStore } from '@/store/chain-account'
import { checkNeedOAuth, OAuthCallBack, showOAuthConfirm } from './index'

export const checkUpSignToken = () => {
  const up_sign_token = getUpSignToken()
  let needOAuth = false
  if (up_sign_token) {
    const decoded = jwt_decode(up_sign_token) as any
    if (dayjs(decoded.exp * 1000).isBefore(dayjs())) needOAuth = true
  } else {
    needOAuth = true
  }
  return needOAuth
}

export const getUpSignToken = () => {
  const oauthUserInfo = getOAuthUserInfo()
  if (!oauthUserInfo) {
    useUserStore().exit()
    return undefined
  }

  const { up_sign_token } = oauthUserInfo
  return up_sign_token
}

export const clearUpSignToken = () => {
  const oauthUserInfo = getOAuthUserInfo()
  if (!oauthUserInfo) {
    useUserStore().exit()
    return undefined
  }
  const { up_sign_token = '' } = oauthUserInfo
  const decoded = jwt_decode(up_sign_token) as any
  if (decoded && decoded.isDisposable) {
    LocalStorageService.set(
      'OAUTH_INFO',
      JSON.stringify({ ...oauthUserInfo, up_sign_token: undefined }),
    )
  }
}

export const updateUpSignToken = async (idToken: string, cb: OAuthCallBack) => {
  if (!idToken) return
  const { sub: newSub, nonce } = jwt_decode<IdTokenParams>(idToken)
  const oauthInfo = getOAuthUserInfo()
  if (newSub !== oauthInfo?.sub) {
    handleWrongAccount(oauthInfo?.email ?? '', cb)
    return
  }
  let res: any
  const duration = parseInt(LocalStorageService.get('UP_SIGN_TOKEN_DURATION') || '60')
  if (nonce.startsWith('update-up-sign-token')) {
    res = await api.signToken({
      idToken,
      duration,
    })
  } else {
    res = await api.sendSyncOAuthSig({ idToken, duration })
  }

  const oauthUserInfo = getOAuthUserInfo()
  if (res.ok && oauthUserInfo) {
    const { authorization, upSignToken } = res.data
    LocalStorageService.set(
      'OAUTH_INFO',
      JSON.stringify({ ...oauthUserInfo, authorization, up_sign_token: upSignToken }),
    )
  } else {
    useUserStore().exit()
  }
}

export const handleOAuthForSetGuardian = async () => {
  LocalStorageService.set('GUARDIAN_ORIGIN_STATE', true)
  const { accountInfo } = useUserStore()
  const { email, oauth_provider } = accountInfo
  const subject = genUpSignTokenSubject()
  if (oauth_provider === 0) {
    // google oauth
    window.location.href = genGoogleOAuthSignUrl(subject, email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: undefined,
      email,
      nonce: subject,
      scope: 'openid',
      provider: oauth_provider,
    })
  }
}

export const checkUpSignTokenForSetGuardian = async () => {
  const needAuth = checkNeedOAuth('UP_SIGN_TOKEN')
  if (needAuth) showOAuthConfirm(handleOAuthForSetGuardian)
  return needAuth
}

export const handleOAuthForCancelRecovery = async () => {
  LocalStorageService.set('CANCEL_RECOVERY_ORIGIN_STATE', true)
  const { accountInfo } = useUserStore()
  const { email, oauth_provider } = accountInfo
  const subject = genUpSignTokenSubject()
  if (oauth_provider === 0) {
    // google oauth
    window.location.href = genGoogleOAuthSignUrl(subject, email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: undefined,
      email,
      nonce: subject,
      scope: 'openid',
      provider: oauth_provider,
    })
  }
}

export const checkUpSignTokenForCancelRecovery = async () => {
  const needAuth = checkNeedOAuth('UP_SIGN_TOKEN')
  if (needAuth) {
    showOAuthConfirm(handleOAuthForCancelRecovery)
  }
  return needAuth
}

export const handleOAuthForSignMessage = async (signStore: string) => {
  LocalStorageService.set('SIGN_MESSAGE_ORIGIN_STATE', signStore)
  const { accountInfo } = useUserStore()
  const { email, oauth_provider } = accountInfo
  const subject = genUpSignTokenSubject()
  if (oauth_provider === 0) {
    // google oauth
    window.location.href = genGoogleOAuthSignUrl(subject, email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: undefined,
      email,
      nonce: subject,
      scope: 'openid',
      provider: oauth_provider,
    })
  }
}

export const checkUpSignTokenForSignMessage = async (signStore: string) => {
  const needAuth = checkNeedOAuth('UP_SIGN_TOKEN')
  if (needAuth) {
    showOAuthConfirm(() => handleOAuthForSignMessage(signStore))
  }
  return needAuth
}

export const handleOAuthForSendTransaction = async (
  type: 'upSignToken' | 'multiSync',
  signStore: string,
) => {
  LocalStorageService.set('SIGN_TX_ORIGIN_STATE', signStore)
  const { accountInfo } = useUserStore()
  const { email, address, oauth_provider, keyset } = accountInfo
  let subject = ''
  if (type === 'upSignToken') {
    subject = genUpSignTokenSubject()
  } else {
    const metaNonce = await blockchain.getMetaNonce(address)
    subject = await getSyncAccountDigestMessage(keyset.hash, metaNonce, address)
  }
  if (oauth_provider === 0) {
    window.location.href = genGoogleOAuthSignUrl(subject, email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: undefined,
      email,
      nonce: subject,
      scope: 'openid',
      provider: oauth_provider,
    })
  }
}

export const handleOAuthForConnect = async () => {
  LocalStorageService.set('CONNECT_ORIGIN_STATE', true)
  const { accountInfo } = useUserStore()
  const { email, oauth_provider } = accountInfo
  let subject = ''
  subject = genUpSignTokenSubject()
  if (oauth_provider === 0) {
    window.location.href = genGoogleOAuthSignUrl(subject, email)
  } else {
    const oauthLoginStore = useOAuthLoginStore()
    await oauthLoginStore.auth0Login({
      prompt: undefined,
      email,
      nonce: subject,
      scope: 'openid',
      provider: oauth_provider,
    })
  }
}

export const checkStatusForSendTransaction = (
  type: 'upSignToken' | 'multiSync',
  signStore: string,
) => {
  showOAuthConfirm(() => handleOAuthForSendTransaction(type, signStore))
}

export const checkUpSignTokenExpiredForConnectPage = async () => {
  const needAuth = checkNeedOAuth('UP_SIGN_TOKEN')
  if (needAuth) showOAuthConfirm(handleOAuthForConnect)
  return needAuth
}

const genUpSignTokenSubject = () => {
  return `update-up-sign-token+${randomString()}`
}

const getSyncAccountDigestMessage = async (
  keysetHash: string,
  metaNonce: number,
  accountAddress: string,
): Promise<string> => {
  metaNonce = metaNonce - 1
  const chainAccountStore = useChainAccountStore()
  const implementationAddress = genUnipassWalletContext().moduleMainUpgradable

  // console.log(`keysetHash: ${keysetHash}`)
  // console.log(`metaNonce: ${metaNonce}`)
  // console.log(`accountAddress: ${accountAddress}`)
  // console.log(`lockDuration: ${chainAccountStore.lockDuration}`)
  // console.log(`implementationAddress: ${implementationAddress}`)

  const txBuilder = new SyncAccountTxBuilder(
    accountAddress,
    metaNonce,
    keysetHash,
    chainAccountStore.lockDuration,
    implementationAddress,
    false,
  )

  return txBuilder.digestMessage()
}
