import Axios from 'axios'
import dayjs from 'dayjs'

const BASE_URL = 'https://datainfo.mixmarvel.com/sync_data'

const axios = Axios.create({ baseURL: BASE_URL })

export const reportDeheroEvent = (event_name: string) => {
  const distinct_id = sessionStorage?.getItem('__distinctId')
  if (!distinct_id) return

  const bodyFormData = new FormData()

  bodyFormData.append('appid', 'bc582c257c0a4876a97f1e68f7873a1e')
  bodyFormData.append('debug', '1')
  bodyFormData.append(
    'data',
    JSON.stringify({
      '#time': dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
      '#distinct_id': distinct_id,
      '#type': 'track',
      '#event_name': event_name,
    }),
  )

  return axios({
    method: 'post',
    data: bodyFormData,
  })
}
