const eth = [
  {
    chainId: 5,
    contractAddress: '0x365E05Fd986245d14c740c139DF8712AD8807874',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 5,
    contractAddress: '0xd44BB808bfE43095dBb94c83077766382D63952a',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    logoURI: '',
  },
]

const polygon = [
  {
    chainId: 80001,
    contractAddress: '0x87F0E95E11a49f56b329A1c143Fb22430C07332a',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
    default: true,
  },
  {
    chainId: 80001,
    contractAddress: '0x569F5fF11E259c8e0639b4082a0dB91581a6b83e',
    name: 'USDT',
    symbol: 'USDT',
    default: true,
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 80001,
    contractAddress: '0x665f1c610b32bb793e9ae5f09ea5dddd0e407e1a',
    name: 'fxSLF',
    symbol: 'fxSLF',
    default: true,
    decimals: 6,
    logoURI: '',
  },
]

const bsc = [
  {
    chainId: 97,
    contractAddress: '0x64544969ed7EBf5f083679233325356EbE738930',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
]

const rangers = [
  {
    chainId: 9527,
    contractAddress: '0xd6ed1c13914ff1b08737b29de4039f542162cae1',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const scroll = [
  {
    chainId: 534353,
    contractAddress: '0xA0D71B9877f44C744546D649147E3F1e70a93760',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
]

const arbitrum = [
  {
    chainId: 421613,
    contractAddress: '0x8667Bfb67d4D9fd1e61168dc872e17f637964547',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 421613,
    contractAddress: '0x84f3eBe8048C5047b35bd2c70E1EEE4dC4b755b6',
    name: 'SLF',
    symbol: 'SLF',
    decimals: 6,
    logoURI: '',
  },
]

const avalanche = [
  {
    chainId: 43113,
    contractAddress: '0x5425890298aed601595a70AB815c96711a31Bc65',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const kcc = [
  {
    chainId: 59,
    contractAddress: '0xd6c7e27a598714c2226404eb054e0c074c906fc9',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
]

const platon = [
  {
    chainId: 2206132,
    contractAddress: '0xEd5e318045D33611E877C25F7aFE6e98e2c2933C',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const okc = [
  {
    chainId: 66,
    contractAddress: '0x6b2b3F5a58c4C258f63b948566581787E45D651E',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

export default { eth, polygon, bsc, rangers, scroll, arbitrum, avalanche, kcc, platon, okc }
