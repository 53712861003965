const eth = [
  {
    chainId: 1,
    contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 1,
    contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const polygon = [
  {
    chainId: 137,
    contractAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 137,
    contractAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const bsc = [
  {
    chainId: 56,
    contractAddress: '0x55d398326f99059fF775485246999027B3197955',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 18,
    logoURI: '',
  },
  {
    chainId: 56,
    contractAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
]

const rangers = [
  {
    chainId: 2025,
    contractAddress: '0x8e8816a1747fddc5f8b45d2e140a425d3788f659',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 18,
    logoURI: '',
  },
]

const arbitrum = [
  {
    chainId: 42161,
    contractAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 42161,
    contractAddress: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const avalanche = [
  {
    chainId: 43114,
    contractAddress: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
    name: 'TetherToken',
    symbol: 'USDt',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 43114,
    contractAddress: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
]

const kcc = [
  {
    chainId: 321,
    contractAddress: '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430',
    name: 'KCC-Peg USD Coin',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
  {
    chainId: 321,
    contractAddress: '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
    name: 'KCC-Peg Tether USD',
    symbol: 'USDT',
    decimals: 18,
    logoURI: '',
  },
]

const platon = [
  {
    chainId: 210425,
    contractAddress: '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: '',
  },
  {
    chainId: 210425,
    contractAddress: '0x97003a080D320eA015BEDba30df25e65Dc32164f',
    name: 'PlatON-Peg Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: '',
  },
]

const okc = [
  {
    chainId: 66,
    contractAddress: '0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 18,
    logoURI: '',
  },
  {
    chainId: 66,
    contractAddress: '0x382bB369d343125BfB2117af9c149795C6C65C50',
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 18,
    logoURI: '',
  },
]

export default { eth, polygon, bsc, rangers, arbitrum, avalanche, kcc, platon, okc }
