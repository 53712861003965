import { aggregate, ICall } from 'makerdao-multicall'
import { weiToEther } from '@/service/format-bignumber'
import { getRpcByChainID, TokenInfo } from '@/service/chains-config'
import { ADDRESS_ZERO, multicallAddress } from '@/service/constants'

export const getBalancesByMulticall = async (
  account: string,
  chainId: number,
  tokens: Array<TokenInfo>,
): Promise<Array<TokenInfo>> => {
  try {
    const calls: Array<Partial<ICall>> = tokens.map(({ contractAddress }) => {
      if (contractAddress === ADDRESS_ZERO) {
        return {
          target: multicallAddress,
          call: ['getEthBalance(address)(uint256)', account],
          returns: [['TOKEN_BALANCE_NATIVE', (val: any) => val]],
        }
      } else {
        return {
          target: contractAddress,
          call: ['balanceOf(address)(uint256)', account],
          returns: [[`TOKEN_BALANCE_${contractAddress}`, (val: any) => val]],
        }
      }
    })
    const ret = await aggregate(calls, {
      rpcUrl: getRpcByChainID(chainId),
      multicallAddress,
    })
    const { transformed } = ret.results

    return tokens.map((token) => {
      if (token.contractAddress === ADDRESS_ZERO) {
        return {
          ...token,
          balance: weiToEther(transformed['TOKEN_BALANCE_NATIVE'], token.decimals),
        }
      } else {
        return {
          ...token,
          balance: weiToEther(
            transformed[`TOKEN_BALANCE_${token.contractAddress}`],
            token.decimals,
          ),
        }
      }
    })
  } catch (e) {
    console.error('getBalancesByMulticall failed', e)
    return tokens.map((token) => {
      return {
        ...token,
        balance: '0',
      }
    })
  }
}
