export const unrealConnectReady = (cb: (value: string) => void) => {
  if (!window?.ue) return
  window.onConnectPageReady = (connectValue: string) => {
    try {
      cb(connectValue)
    } catch (err) {
      console.error('unrealConnectReady', err)
    }
  }
}

export const unrealSignMessageReady = (cb: (value: string) => void) => {
  if (!window?.ue) return
  window.onSignMessagePageReady = (signMessageValue: string) => {
    try {
      cb(signMessageValue)
    } catch (err) {
      console.error('unrealSignMessageReady', err)
    }
  }
}

export const unrealSendTransactionReady = (cb: (value: string) => void) => {
  if (!window?.ue) return
  window.onSendTransactionPageReady = (sendTransactionValue: string) => {
    try {
      cb(sendTransactionValue)
    } catch (err) {
      console.error('unrealSendTransactionReady', err)
    }
  }
}
