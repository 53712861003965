import { useCoinStore } from '@/store/coin'
import { useUserStore } from '@/store/user'
import { checkAuthorizationExpired } from '@/utils/oauth/check-authorization'
import { NavigationGuardWithThis, NavigationHookAfter } from 'vue-router'
import { upGA } from '@/utils/useUniPass'
import { cachePathForOAuth } from '@/service/check-environment'

let appInited = false

// path don't need check authorization
const AUTH_LESS_ROUTE_PATH = ['/login', '/connect', '/loading', '/wc', '/logout', '/send-code']

export const initUser: NavigationGuardWithThis<undefined> = async (to) => {
  if (appInited) return
  const userStore = useUserStore()
  appInited = true
  try {
    // do nothing
    if (AUTH_LESS_ROUTE_PATH.indexOf(to.path) !== -1) {
      return
    }
    // clear userInfo
    if (['/register', '/recovery', '/recovery/result', '/404'].indexOf(to.path) !== -1) {
      await userStore.exit()
      return
    }
    if (['/sign-message', '/send-transaction'].indexOf(to.path) !== -1) {
      cachePathForOAuth(to.fullPath)
    }
    // init userInfo
    const res = await userStore.init(to.path !== '/login')
    // if user logged check auth
    if (res) await checkAuthorizationExpired()
    return
  } catch (e) {
    console.warn('Navigation failed: ', e)
  }
  return {
    path: '/404',
  }
}

export const userCheck: NavigationGuardWithThis<undefined> = async () => {
  const userStore = useUserStore()
  if (userStore.isLogin) {
    return {
      path: '/',
    }
  }
}

export const routerGA: NavigationHookAfter = (to, from) => {
  upGA('router_change', {
    from: from.path,
    to: to.path,
  })
}

export const netWorthPolling: NavigationGuardWithThis<undefined> = (to, from) => {
  const coinStore = useCoinStore()
  if (from.path === '/') {
    coinStore.stop()
  }

  if (to.path === '/') {
    coinStore.start()
  }
}
