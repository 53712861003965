import api from '@/service/backend';
import { useUserStore } from './user';
export const useNftStore = defineStore({
  id: 'nftStore',
  state: () => {
    return {
      nft: {
        tokenId: '',
        tokenType: '',
        timeLastUpdated: '',
        total: 0,
        title: '',
        description: '',
        imageUrl: '',
        imageOriginalUrl: ''
      },
      collection: {
        contractAddress: '',
        tokenType: '',
        totalTokens: 0,
        name: '',
        symbol: '',
        imageUrl: '',
        chainId: '',
        openseaUrl: '',
        browserUrl: '',
        timeLastUpdated: ''
      },
      activeTab: 'token',
      activeCollectionIndex: -1,
      collectionList: [],
      collectionCache: {},
      currentCollection: [],
      nftLoading: false,
      collectionLoading: true,
      currentChainIndex: 0
    };
  },
  actions: {
    clickCollection(item, i) {
      var _this$collectionCache;

      this.activeCollectionIndex = this.activeCollectionIndex === i ? -1 : i;

      if ((_this$collectionCache = this.collectionCache[item.contractAddress]) !== null && _this$collectionCache !== void 0 && _this$collectionCache.length) {
        this.currentCollection = this.collectionCache[item.contractAddress];
      } else {
        this.currentCollection = [];
        this.loadNFT(item, i);
      }
    },

    async loadCollection(address) {
      if (!address) {
        return null;
      }

      const chainId = useUserStore().chainId;

      try {
        var _res$data;

        const res = await api.getAccountCollection({
          address,
          chainId,
          page: 1,
          size: 100
        });

        if (res !== null && res !== void 0 && (_res$data = res.data) !== null && _res$data !== void 0 && _res$data.list) {
          this.collectionList = res.data.list;
        }
      } catch (error) {
        console.error('updateCollection', error);
      }

      this.collectionLoading = false;
    },

    async loadNFT(item, i) {
      const address = useUserStore().accountInfo.address;

      if (!address) {
        return null;
      }

      if (this.nftLoading) {
        return null;
      }

      this.nftLoading = true;
      const {
        contractAddress,
        chainId
      } = item;

      try {
        const res = await api.getAccountNFT({
          address,
          chainId,
          contractAddress: contractAddress,
          page: 1,
          size: 100
        });
        this.collectionCache[contractAddress] = res.data.list;

        if (i === this.activeCollectionIndex) {
          this.currentCollection = this.collectionCache[contractAddress];
        }
      } catch (error) {
        console.error('loadNFT', error);
      }

      this.nftLoading = false;
    }

  }
});