import Dexie from 'dexie'
import { AccountInfo } from './oauth-login'
import { getOAuthUserInfo } from './storages'
import { useUserStore } from '@/store/user'

const dbName = 'UniPassWalletIndexDB_v2'
const timeoutDuration = 5000
const db = new Dexie(dbName)
db.version(1).stores({ users: 'email_provider' })

interface DBProps {
  getAccountInfo: (exit?: boolean) => Promise<AccountInfo | undefined>
  setAccountInfo: (account: AccountInfo) => Promise<void>
  clearAccountInfo: () => Promise<void>
}

const genUserKey = () => {
  const oauthUserInfo = getOAuthUserInfo()
  if (oauthUserInfo) {
    const { email, oauth_provider } = oauthUserInfo
    return `${email}_${oauth_provider}`
  }
  return ''
}

const DB: DBProps = {
  async getAccountInfo(exit = true) {
    const userStore = useUserStore()
    try {
      const _account_info = (await db
        .table('users')
        .get(genUserKey())
        .timeout(timeoutDuration)) as AccountInfo
      if (!_account_info) {
        if (exit) userStore.exit()
        return
      }
      return _account_info
    } catch (err) {
      console.error('DB getAccountInfo error:', err)
      if (exit) userStore.exit()
    }
  },
  async setAccountInfo(account: AccountInfo) {
    try {
      await db
        .table('users')
        .put({ ...account, email_provider: genUserKey() })
        .timeout(timeoutDuration)
    } catch (err) {
      console.error('DB setAccountInfo error:', err)
      return undefined
    }
  },
  async clearAccountInfo() {
    try {
      const res = await db.table('users').delete(genUserKey()).timeout(timeoutDuration)
      return res
    } catch (err) {
      console.error('DB clearAccountInfo error:', err)
      return undefined
    }
  },
}

export default DB
