import "core-js/modules/es.json.stringify.js";
import { UPMessage, UPResponse } from '@unipasswallet/popup-types';
import { getDefaultChainIdForIndexPage, sdkConfig } from '@/service/chains-config';
import UnipassWalletProvider from '@unipasswallet/provider';
import router from '@/plugins/router';
import { postMessage } from '@unipasswallet/popup-utils';
import { useChainAccountStore } from './chain-account';
import { clearStorage } from './../utils/clear';
import DB from './index-db';
import { AccountKeyType, OAuthProvider } from '@/service/enum';
import { useNftStore } from '@/store/nft';
import { useCoinStore } from './coin';
import { getDefaultTokens } from '@/utils/account-token';
export const useUserStore = defineStore({
  id: 'userStore',
  state: () => {
    return {
      unipassWallet: UnipassWalletProvider.getInstance({
        env: sdkConfig.net,
        url_config: sdkConfig.urlConfig
      }),
      accountInfo: {
        email: '',
        id_token: '',
        user_key: {
          encrypted_key: ''
        },
        address: '',
        oauth_provider: OAuthProvider.GOOGLE,
        keyset: {
          hash: '',
          masterKeyAddress: '',
          keysetJson: '',
          keyType: AccountKeyType.MPC
        }
      },
      appInited: false,
      showSupportEmail: false,
      showHeaderMore: false,
      showOAuth: false,
      showOAuthConfirmLoading: false,
      showOAuthHours: 24,
      showOAuthCallback: async () => {},
      upLoading: false,
      theme: '',
      chainId: getDefaultChainIdForIndexPage()
    };
  },
  getters: {
    isLogin(state) {
      var _state$accountInfo;

      return !!(state !== null && state !== void 0 && (_state$accountInfo = state.accountInfo) !== null && _state$accountInfo !== void 0 && _state$accountInfo.address);
    }

  },
  actions: {
    async init(exit = true) {
      const account_info = await DB.getAccountInfo(exit);
      if (!account_info) return;
      this.accountInfo = account_info;
      return this.accountInfo;
    },

    async update(accountInfo) {
      this.accountInfo = accountInfo;
      await DB.setAccountInfo(accountInfo);
    },

    async exit(refresh = false, redirectUrl) {
      var _window, _window$flutter_inapp;

      await clearStorage();
      useChainAccountStore().$reset();
      sessionStorage.removeItem('chainId');
      this.$reset();
      this.upLoading = false;

      if (window.opener) {
        postMessage(new UPMessage('UP_RESPONSE', JSON.stringify(new UPResponse('DECLINE', 'expired'))));
      }

      if ((_window = window) !== null && _window !== void 0 && (_window$flutter_inapp = _window.flutter_inappwebview) !== null && _window$flutter_inapp !== void 0 && _window$flutter_inapp.callHandler) {
        window.flutter_inappwebview.callHandler('onUserInfoInvalid', new UPMessage('UP_RESPONSE', JSON.stringify(new UPResponse('DECLINE', 'expired'))));
      }

      if (refresh) {
        window.location.replace('/login');
      } else if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        router.replace('/login');
      }
    },

    async checkKeysetHash() {
      const chainAccountStore = useChainAccountStore();
      await chainAccountStore.fetchAccountInfo(this.accountInfo.address, true, this.accountInfo.keyset.hash);

      if (this.accountInfo.keyset.hash !== chainAccountStore.keysetHash) {
        this.exit();
        return false;
      }

      return true;
    },

    updateChainId(chainId) {
      var _sessionStorage;

      if (this.chainId === chainId) return;
      this.chainId = chainId;
      (_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 ? void 0 : _sessionStorage.setItem('chainId', chainId.toString());
      const address = this.accountInfo.address;

      if (address) {
        // nft
        const nftStore = useNftStore();
        nftStore.collectionLoading = true;
        nftStore.collectionList = []; // token

        const coinStore = useCoinStore();
        coinStore.netWorth = '-';
        coinStore.coins = getDefaultTokens(chainId, address); // load

        coinStore.fetchBalances();
      }
    }

  }
});