interface IRoleWeight {
  ownerWeight: number
  guardianWeight: number
  assetsOpWeight: number
}
export class Weight {
  static getMasterKeyWeight(): IRoleWeight {
    return {
      ownerWeight: 60,
      guardianWeight: 0,
      assetsOpWeight: 100,
    }
  }

  static getCustodialMasterKeyWeight(): IRoleWeight {
    return {
      ownerWeight: 100,
      guardianWeight: 0,
      assetsOpWeight: 100,
    }
  }

  static getPolicyWeight(): IRoleWeight {
    return {
      ownerWeight: 40,
      guardianWeight: 0,
      assetsOpWeight: 0,
    }
  }

  static getRegisterEmailWeight(): IRoleWeight {
    return {
      ownerWeight: 60,
      guardianWeight: 60,
      assetsOpWeight: 0,
    }
  }

  static getSelfGuardianWeight(): IRoleWeight {
    return {
      ownerWeight: 50,
      guardianWeight: 50,
      assetsOpWeight: 0,
    }
  }

  static getOneGuardianWeight(): IRoleWeight {
    return {
      ownerWeight: 0,
      guardianWeight: 50,
      assetsOpWeight: 0,
    }
  }

  static getMoreGuardianWeight(): IRoleWeight {
    return {
      ownerWeight: 0,
      guardianWeight: 40,
      assetsOpWeight: 0,
    }
  }
}
