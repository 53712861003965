import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.error.cause.js";
import { getCachedOAuthPath, isIframeEnv } from './../service/check-environment';
import { Wallet } from 'ethers';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import { Keyset } from '@unipasswallet/keys';
import api from '@/service/backend';
import { encryptKeystoreByKMS, decryptKeyStoreByKMS } from '@/utils/oauth/aws-config';
import { getCustodialAccountJson } from '@/utils/rbac';
import { encryptSessionKey } from '@/utils/session-key';
import Tss from '@/utils/tss';
import { upSuccess, upError, upGA } from '@/utils/useUniPass';
import { getOAuthUserInfo, LocalStorageService } from './storages';
import DB from './index-db';
import { useUserStore } from './user';
import blockchain from '@/service/blockchain';
import { AccountKeyType } from '@/service/enum';
import { handleRedirectEnvAfterOAuth } from '@/utils/oauth/check-authorization';
import { reportDeheroEvent } from '@/service/third-parity-backend';
const {
  t: $t
} = i18n.global;
export const useKMSLoginStore = defineStore({
  id: 'KMSLoginStore',
  state: () => {
    return {
      email: '',
      code: '',
      submitLoading: false,
      sendLoading: false,
      countDown: 0,
      countDownTimer: undefined,
      isUpdateUpSignToken: false
    };
  },
  actions: {
    verifyCodeForCustodial(email) {
      this.email = email;
      this.code = '';
      this.isUpdateUpSignToken = false;
      this.submitLoading = false;
      reportDeheroEvent('start_signup');
      router.push('/verify-code');
    },

    updateUpSignTokenCustodial(email) {
      this.email = email;
      this.code = '';
      this.isUpdateUpSignToken = true;
      this.submitLoading = false;
      router.push('/verify-code');
    },

    async sendCode() {
      if (!this.email) return;

      try {
        this.sendLoading = true;
        this.stopCountDown();
        const res = this.isUpdateUpSignToken ? await api.sendOtpCode({
          action: 'auth2Fa',
          authType: 0
        }) : await api.sendCode(this.email);

        if (res.ok) {
          upSuccess($t('SendSuccess'));
          this.startCountDown();
        } else {
          this.stopCountDown();
        }
      } catch {
        upError('send code failed');
        this.stopCountDown();
      } finally {
        this.sendLoading = false;
      }
    },

    startCountDown() {
      this.countDown = 60;
      this.countDownTimer = setInterval(() => {
        this.countDown -= 1;

        if (this.countDown <= 0) {
          this.stopCountDown();
        }
      }, 1000);
    },

    stopCountDown() {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
      this.countDown = 0;
    },

    async verifyCode() {
      this.submitLoading = true;

      if (this.isUpdateUpSignToken) {
        this.verifyCodeForUpSignToken();
      } else {
        this.verifyCodeForSign();
      }
    },

    async verifyCodeForSign() {
      try {
        this.submitLoading = true;
        const res = await api.verifyCode(this.email, this.code);

        if (res.ok) {
          var _cognitoResult$idToke;

          reportDeheroEvent('verify_success');
          const {
            cognitoResult,
            provider,
            isRegistered,
            authorization,
            upSignToken,
            unipassInfo
          } = res.data; // TODO complete user info

          const oauthUserInfo = {
            sub: '',
            oauth_provider: provider,
            authorization,
            id_token: (_cognitoResult$idToke = cognitoResult === null || cognitoResult === void 0 ? void 0 : cognitoResult.idToken) !== null && _cognitoResult$idToke !== void 0 ? _cognitoResult$idToke : '',
            email: this.email,
            expires_at: '',
            up_sign_token: upSignToken,
            unipass_info: unipassInfo
          };
          LocalStorageService.set('OAUTH_INFO', JSON.stringify(oauthUserInfo)); // login or register

          if (isRegistered) {
            await this.encryptLogin(oauthUserInfo, cognitoResult);
          } else {
            await this.encryptSignUp(oauthUserInfo, cognitoResult);
          }

          this.code = '';
          this.stopCountDown();
        }
      } catch (e) {
        console.error('kms verify code error', e);
        reportDeheroEvent('verify_failed');
      } finally {
        this.submitLoading = false;
      }
    },

    async verifyCodeForUpSignToken() {
      try {
        this.submitLoading = true;
        const duration = parseInt(LocalStorageService.get('UP_SIGN_TOKEN_DURATION') || '60');
        const res = await api.upSignToken(this.code, 0, duration);

        if (res.ok) {
          const {
            authorization,
            upSignToken
          } = res.data;
          const oauthUserInfo = getOAuthUserInfo();
          LocalStorageService.set('OAUTH_INFO', JSON.stringify({ ...oauthUserInfo,
            authorization,
            up_sign_token: upSignToken
          }));
          router.back();
        }
      } catch (e) {
        console.error('update sign token verify code error', e);
      } finally {
        this.submitLoading = false;
      }
    },

    async encryptSignUp(oauthUserInfo, cognitoResult) {
      try {
        var _sessionStorage;

        if (!cognitoResult) throw new Error('cognito result not find');
        const {
          email,
          id_token,
          expires_at,
          oauth_provider
        } = oauthUserInfo;
        const localKeyData = await Tss.generateLocalKey({
          email,
          action: 'signUp'
        });
        if (!localKeyData) return;
        const encryptedKeystore = await encryptKeystoreByKMS(localKeyData.keystore, cognitoResult);
        const {
          encrypted_key,
          aes_key
        } = await encryptSessionKey(localKeyData.keystore);
        const pepper = Wallet.createRandom().privateKey;
        const keyset = getCustodialAccountJson(localKeyData.localKeyAddress);
        const res = await api.signUpAccount({
          keysetJson: keyset.toJson(),
          masterKey: {
            masterKeyAddress: localKeyData.localKeyAddress,
            keyStore: encryptedKeystore,
            keyType: AccountKeyType.AWS_KMS
          },
          pepper,
          source: ((_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 ? void 0 : _sessionStorage.getItem('up_login_source')) || LocalStorageService.get('UP_CONNECT_SOURCE') || 'unipass'
        });
        const accountAddress = blockchain.generateAccountAddress(keyset.hash());

        if (res.ok) {
          sessionStorage.removeItem('up_login_source');
          reportDeheroEvent('signup_success');
          const {
            address,
            authorization,
            upSignToken
          } = res.data;

          if (address.toLowerCase() === accountAddress.toLowerCase()) {
            const user = {
              email,
              id_token,
              user_key: {
                encrypted_key,
                aes_key
              },
              address: accountAddress,
              oauth_provider,
              expires_at,
              keyset: {
                hash: keyset.hash(),
                masterKeyAddress: localKeyData.localKeyAddress,
                keysetJson: keyset.obscure().toJson(),
                keyType: AccountKeyType.AWS_KMS
              },
              keystore: encryptedKeystore
            };
            await DB.setAccountInfo(user);
            await useUserStore().init();
            LocalStorageService.set('OAUTH_INFO', JSON.stringify({ ...oauthUserInfo,
              authorization,
              up_sign_token: upSignToken
            }));
            sessionStorage.newborn = true;

            if (isIframeEnv()) {
              await router.replace(getCachedOAuthPath());
              return;
            }

            router.replace('/register/loading');
          } else {
            console.error('signUp account address inconsistent');
          }
        } else {}
      } catch (e) {
        console.error('sign up failed', e);
        router.replace('/login');
      }
    },

    async encryptLogin(oauthUserInfo, cognitoResult) {
      try {
        if (!cognitoResult) throw new Error('cognito result not find');
        const {
          email,
          id_token,
          expires_at,
          unipass_info,
          oauth_provider
        } = oauthUserInfo;
        if (!(unipass_info !== null && unipass_info !== void 0 && unipass_info.keystore)) throw new Error('unipass_info not find when login');
        const decryptedKeyStore = await decryptKeyStoreByKMS(unipass_info.keystore, cognitoResult);
        const {
          encrypted_key,
          aes_key
        } = await encryptSessionKey(decryptedKeyStore);
        const keyset = Keyset.fromJson(unipass_info.keyset);
        const user = {
          email,
          id_token,
          user_key: {
            encrypted_key,
            aes_key
          },
          address: unipass_info.address,
          oauth_provider,
          expires_at,
          keyset: {
            hash: keyset.hash(),
            masterKeyAddress: keyset.keys[0].address,
            keysetJson: keyset.obscure().toJson(),
            keyType: (unipass_info === null || unipass_info === void 0 ? void 0 : unipass_info.keyType) || AccountKeyType.AWS_KMS
          },
          keystore: unipass_info.keystore
        };
        await DB.setAccountInfo(user);
        await useUserStore().init();
        sessionStorage.removeItem('newborn');
        handleRedirectEnvAfterOAuth();
        upGA('login_success', {
          account: unipass_info.address,
          email
        }, oauth_provider);
        reportDeheroEvent('signin_success');
      } catch (e) {
        if ((e === null || e === void 0 ? void 0 : e.message) === 'invalid password') {
          upError($t('IncorrectPassword'));
          return;
        }

        console.error('login error', e);
        router.replace('/login');
      }
    } // createTurnstile() {
    //   if (window.turnstile === null || !window.turnstile) {
    //     const script = document.createElement('script')
    //     script.src =
    //       'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'
    //     script.async = true
    //     script.defer = true
    //     document.head.appendChild(script)
    //     window.onloadTurnstileCallback = this.renderTurnstile
    //   } else {
    //     this.renderTurnstile()
    //   }
    // },
    // renderTurnstile() {
    //   console.log('renderTurnstile')
    //   window.turnstile?.render('#turnstile-box', {
    //     sitekey: '0x4AAAAAAADZGItInrtVyWqs',
    //     appearance: 'interaction-only',
    //     callback: (response: string) => {
    //       console.log(response)
    //       if (this.isUpdateUpSignToken) {
    //         this.verifyCodeForUpSignToken(response)
    //       } else {
    //         this.verifyCodeForSign(response)
    //       }
    //     },
    //     'expired-callback': () => {
    //       console.log('turnstile expired')
    //     },
    //     'error-callback': () => {
    //       console.log('turnstile error')
    //       this.submitLoading = false
    //     },
    //   })
    // },


  }
});