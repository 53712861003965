import {
  clearCachedRedirectUrl,
  isFlutterEnv,
  isPopupEnv,
  isRedirectEnv,
  isUnityEnv,
  isUnRealEnv,
} from '@/service/check-environment'
import { UPAccount, UPMessage, UPResponse } from '@unipasswallet/popup-types'
import { postMessage as popupPostMessage } from '@unipasswallet/popup-utils'
import { utils } from 'ethers'
import { ElMessageBox } from 'element-plus'
import i18n from '@/plugins/i18n'
import { LocalStorageService } from '@/store/storages'

export * from './flutter_side'
export * from './unity_side'
export * from './unreal_side'

export const addressInconsistent = (options: { type?: string }) => {
  const { t: $t } = i18n.global
  LocalStorageService.remove('OAUTH_REDIRECT')

  ElMessageBox.alert($t('AddressInconsistentTip'), $t('Notification'), {
    confirmButtonText: $t('Confirm'),
    center: true,
    showClose: false,
    showCancelButton: false,
  })
    .then(() => {
      if (isPopupEnv()) {
        popupPostMessage(
          new UPMessage('UP_RESPONSE', JSON.stringify(new UPResponse('DECLINE', 'invalid login'))),
        )
      }

      if (isFlutterEnv()) {
        window.flutter_inappwebview.callHandler(
          'onUserInfoInvalid',
          new UPMessage('UP_RESPONSE', JSON.stringify(new UPResponse('DECLINE', 'invalid login'))),
        )
      }

      if (isUnityEnv()) {
        window.vuplex.postMessage(
          new UPMessage('UP_RESPONSE', JSON.stringify(new UPResponse('DECLINE', 'invalid login'))),
        )
        return
      }

      // TODO fix invalid login
      if (isUnRealEnv()) {
        window?.ue?.unipass?.onlogin(new UPAccount('', '', false))
        window?.ue?.unipass?.onsignmessage('')
        window?.ue?.unipass?.onsendtransaction('')
        return
      }

      const redirectUrl = isRedirectEnv()
      if (redirectUrl) {
        const url = redirectUrl.searchParams.get('redirectUrl')
        const response = {
          type: options.type,
          errorCode: 409,
          errorMsg: 'Address mismatch. Please reconnect to UniPass in the app for authentication.',
        }
        const base64Response = utils.base64.encode(utils.toUtf8Bytes(JSON.stringify(response)))
        clearCachedRedirectUrl()
        // awake native app
        location.href = `${decodeURI(url as string)}#${base64Response}`
        return
      }
    })
    .catch(() => {})
}
