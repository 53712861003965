import Web3Auth from '@web3auth/single-factor-auth'
import { BigNumber } from 'ethers'
import { OAuthProvider } from './enum'
import { getChainIdByChainType, getCurrentEnv } from './chains-config'
import { LocalStorageService } from '@/store/storages'

const WEB3_AUTH_SOURCE = ['legend of arcadia', 'loa']

function getVerifier(provider: OAuthProvider) {
  switch (provider) {
    case OAuthProvider.APPLE:
      return 'UniPass-Auth0-Apple-Verifier'
    case OAuthProvider.GOOGLE:
      return 'UniPass-Google-Verifier'
    case OAuthProvider.AUTH0:
      return 'UniPass-Auth0-Email-Verifier'
    default:
      return 'UniPass-Auth0-Email-Verifier'
  }
}

export function isWeb3AuthKeyType() {
  console.log('isWeb3AuthKeyType: ', LocalStorageService.get('UP_CONNECT_SOURCE'))
  return WEB3_AUTH_SOURCE.indexOf(LocalStorageService.get('UP_CONNECT_SOURCE').toLowerCase()) !== -1
}

export async function getWeb3AuthPrivateKey(
  oauthProvider: OAuthProvider,
  idToken: string,
  verifierId: string,
): Promise<string> {
  console.log('in getWeb3AuthPrivateKey')
  console.log(getChainIdByChainType('eth'))
  console.log('chainId: ', BigNumber.from(getChainIdByChainType('eth')).toHexString())
  const web3auth = new Web3Auth({
    clientId: process.env.VUE_APP_WEB3AUTH_CLIENTID!, // Get your Client ID from Web3Auth Dashboard
    chainConfig: {
      chainNamespace: 'eip155',
      chainId: BigNumber.from(getChainIdByChainType('eth')).toHexString(),
      rpcTarget: process.env.VUE_APP_ETH_RPC, // needed for non-other chains
    },
    // Available networks are "testnet", "mainnet", "cyan", "aqua", and "celeste".
    web3AuthNetwork: getCurrentEnv(),
  })

  web3auth.init()

  const provider = await web3auth.connect({
    verifier: getVerifier(oauthProvider), // e.g. `web3auth-sfa-verifier` replace with your verifier name, and it has to be on the same network passed in init().
    verifierId,
    idToken,
  })

  return (await provider?.request({ method: 'eth_private_key' })) as string
}
