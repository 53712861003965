import { utils } from 'ethers'
import AWS from 'aws-sdk'
import { CognitoParams } from '@/service/backend'

const { toUtf8Bytes, toUtf8String, base64 } = utils

const generateKMSInstance = async (cognitoResult: CognitoParams) => {
  const { region, idToken, identityPoolId, userPoolId } = cognitoResult
  AWS.config.region = region
  const cognitoIdentity = new AWS.CognitoIdentity()
  const params = {
    IdentityPoolId: identityPoolId,
    Logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
    },
  }
  const data = await cognitoIdentity.getId(params).promise()
  if (!data.IdentityId) throw new Error('aws credential get id failed')
  const params2 = {
    IdentityId: data.IdentityId,
    Logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
    },
  }
  const data2 = await cognitoIdentity.getCredentialsForIdentity(params2).promise()
  if (!data2.Credentials) throw new Error('get aws credential failed')

  return new AWS.KMS({
    region,
    accessKeyId: data2.Credentials.AccessKeyId ?? '',
    secretAccessKey: data2.Credentials.SecretKey ?? '',
    sessionToken: data2.Credentials.SessionToken ?? '',
  })
}

export async function encryptKeystoreByKMS(
  originKey: string,
  cognitoResult: CognitoParams,
): Promise<string> {
  const { kmsKeyId } = cognitoResult
  const kms = await generateKMSInstance(cognitoResult)
  const params = {
    KeyId: kmsKeyId,
    Plaintext: toUtf8Bytes(originKey),
  }
  const encryptedOriginKey = await kms.encrypt(params).promise()
  if (!encryptedOriginKey.CiphertextBlob) throw new Error('encrypted origin key failed')
  return base64.encode(encryptedOriginKey.CiphertextBlob as Uint8Array)
}

export async function decryptKeyStoreByKMS(
  encryptedKeystore: string,
  cognitoResult: CognitoParams,
): Promise<string> {
  const { kmsKeyId } = cognitoResult
  const kms = await generateKMSInstance(cognitoResult)
  const params = {
    KeyId: kmsKeyId,
    CiphertextBlob: base64.decode(encryptedKeystore),
  }
  const decryptedOriginKey = await kms.decrypt(params).promise()
  if (!decryptedOriginKey.Plaintext) throw new Error('decrypted keystore failed')
  return toUtf8String(decryptedOriginKey.Plaintext as Uint8Array)
}
