import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.error.cause.js";
import { emailDotPrompt, emailLowercaseFormat, emailLowercasePrompt } from '@/utils/email-dict';
import { useSignStore } from '@/store/sign';
import { useUserStore } from '@/store/user';
import { ElMessageBox, ElMessage } from 'element-plus';
import UpIcon from '@/components/up-icon.vue';
import { useGtag } from 'vue-gtag-next';
import { getOpenType } from '@/service/check-environment';
import { hashMessage } from 'ethers/lib/utils';
import i18n from '@/plugins/i18n';
import Bowser from 'bowser';
import { LocalStorageService } from '@/store/storages';
import useClipboard from 'vue-clipboard3';
export const upSuccess = (message, duration = 3000) => {
  ElMessage({
    message,
    type: 'success',
    icon: h(UpIcon, {
      name: 'success'
    }),
    duration,
    center: true,
    customClass: 'up-message-success',
    grouping: true
  });
};
export const upInfo = message => {
  ElMessage({
    message,
    type: 'success',
    icon: h(UpIcon, {
      name: 'success'
    }),
    // duration: 0,
    center: true,
    customClass: 'up-message-info',
    grouping: true
  });
};
export const upError = message => {
  ElMessage({
    message,
    type: 'error',
    icon: h(UpIcon, {
      name: 'wrong'
    }),
    duration: 5000,
    customClass: 'up-message-error',
    grouping: true
  });
};
export const upTip = (message, duration, showClose = false) => {
  ElMessage({
    message,
    type: 'error',
    icon: h(UpIcon, {
      name: 'wrong'
    }),
    duration,
    showClose,
    customClass: 'up-message-error',
    grouping: true
  });
};
export const accountTypeDict = {
  0: 'GoogleOAuth',
  1: 'Email',
  2: 'Apple',
  3: 'UniPassEmail',
  4: 'Custodial_KMS_email'
};
export const upGA = async (eventName, data, oauth_provider) => {
  const accountInfo = useUserStore().accountInfo;
  let {
    address,
    email
  } = accountInfo;

  if (data) {
    email = data.email || email;
    address = data.address || address;
  }

  const provider = oauth_provider || accountInfo.oauth_provider;
  const accountType = accountTypeDict[provider] || '-';
  data = {
    appName: LocalStorageService.get('UP_CONNECT_SOURCE') || 'UniPassWallet',
    referrer: LocalStorageService.get('OAUTH_REFERRER') || 'UniPassWallet',
    uniqueID: accountType && email ? `${accountType}_${hashMessage(email)}` : '-',
    openType: getOpenType(),
    accountType,
    ...data
  };
  data.email = email ? `email_${hashMessage(email)}` : '-';
  data.account = address ? `address_${hashMessage(address)}` : '-'; // console.log('GA:', eventName, data)

  useGtag().event(eventName, data);
};
export const copy = async str => {
  const {
    t: $t
  } = i18n.global;
  const {
    toClipboard
  } = useClipboard();

  try {
    await toClipboard(str);
    upSuccess($t('CopySuccess'));
  } catch (e) {
    console.error('copy failed');
  }
};
export const formatAddress = address => {
  if (!address) {
    return '';
  }

  const prefix = address.slice(0, 6);
  const suffix = address.slice(-4);
  return prefix + '...' + suffix;
};
export const formatEmail = (email, next = false) => {
  const {
    t: $t
  } = i18n.global;

  for (const s of [' ', '+']) {
    email = email.replaceAll(s, '');
  }

  const i = email.indexOf('@');

  if (i !== -1) {
    let prefix = email.slice(0, i);
    let warning = '';
    const suffix = email.slice(i + 1).replaceAll('@', '').toLowerCase();

    if (/[A-Z]/.test(prefix)) {
      if (emailLowercaseFormat.includes(suffix)) {
        prefix = prefix.toLowerCase();
      }

      if (emailLowercasePrompt.includes(suffix)) {
        if (prefix.includes('.') && emailDotPrompt.includes(suffix)) {
          warning = $t('SameCaseDot');
        } else {
          warning = $t('SameCase');
        }
      }
    }

    if (prefix.includes('.')) {
      if (emailDotPrompt.includes(suffix) && !warning) {
        warning = $t('SameDot');
      }
    }

    if (next) {
      return warning;
    }

    email = prefix + '@' + suffix;
  }

  return email;
};
export const formatAmount = (amount, blur) => {
  var _useSignStore$coin;

  amount = amount.replaceAll(/[^\d.]/g, '');
  if (amount.length === 0) return '';
  const decimal = (_useSignStore$coin = useSignStore().coin) === null || _useSignStore$coin === void 0 ? void 0 : _useSignStore$coin.decimals;
  const i = amount.indexOf('.');

  if (i !== -1) {
    let prefix = amount.slice(0, i);
    let suffix = amount.slice(i + 1).replaceAll('.', '');

    if (blur) {
      if (prefix === '') prefix = '0';
      if (suffix === '') suffix = '0';
    }

    suffix = suffix.slice(0, decimal);
    amount = prefix + '.' + suffix;
  }

  return amount;
};
export const formatPassword = password => {
  const re = /[^A-z\d!"#$%&\\'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  return password.replaceAll(re, '');
};
export const formatBalance = (balance, decimals = 4) => {
  return Number(Number(balance).toFixed(decimals));
};
export const formatImgUrl = url => {
  const protocol = url.split('://')[0];

  if (protocol === 'ipfs') {
    return url.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }

  return url;
};
export const checkPassword = (_rule, v, callback) => {
  const {
    t: $t
  } = i18n.global;
  const password = v;

  if (!password) {
    return callback(new Error($t('PasswordEmpty')));
  }

  if (/^\S{8,32}$/.test(password) === false) {
    return callback(new Error($t('PasswordRule1')));
  }

  if (/(?=.*[A-Z])(?=.*\S)[^]/.test(password) === false) {
    return callback(new Error($t('PasswordRule2')));
  }

  if (/(?=[a-z])[^]/.test(password) === false) {
    return callback(new Error($t('PasswordRule3')));
  }

  if (/(?=[\d]+)/.test(password) === false) {
    return callback(new Error($t('PasswordRule4')));
  }

  callback();
};
export const setLanguage = lang => {
  var _localStorage;

  const i18n = useI18n();
  i18n.locale.value = lang;
  (_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.setItem('language', lang);
};
export function isMobile() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatformType(true) === 'mobile';
}
export const useUniPass = () => {
  const {
    t: $t
  } = useI18n();
  const userStore = useUserStore(); // check

  const checkEmailFormat = (_rule, v, callback) => {
    const email = v;

    if (!email) {
      return callback();
    }

    const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (!regex.test(email)) {
      return callback(new Error($t('EmailWrongFormat')));
    }

    callback();
  };

  const checkDkimEmailFormat = (_rule, v, callback) => {
    const email = v;

    if (!email) {
      // return callback(new Error($t('EmailEmpty')))
      return callback();
    }

    const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (!regex.test(email)) {
      return callback(new Error($t('EmailWrongFormat')));
    }

    if (!sessionStorage.mailServices) {
      return callback(new Error($t('NetworkError')));
    }

    let ok = false;

    for (const e of sessionStorage.mailServices.split(',')) {
      if (email.endsWith('@' + e)) {
        ok = true;
      }
    }

    if (!ok) {
      userStore.showSupportEmail = true;
      return callback(new Error($t('NotSupport')));
    }

    callback();
  };

  const userExit = () => {
    ElMessageBox.confirm($t('SureLogOut'), $t('LogOutAccount'), {
      confirmButtonText: $t('LogOut'),
      cancelButtonText: $t('Cancel')
    }).then(() => {
      userStore.exit();
    }).catch(() => {});
  };

  return {
    checkEmailFormat,
    checkDkimEmailFormat,
    checkPassword,
    formatAddress,
    formatEmail,
    formatPassword,
    formatBalance,
    formatAmount,
    copy,
    success: upSuccess,
    error: upError,
    info: upInfo,
    userExit,
    setLanguage
  };
};