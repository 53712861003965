export enum OAuthProvider {
  GOOGLE, // google oauth
  AUTH0, // email auth0
  APPLE, // apple auth0
  UNIPASS, // auth2.0 custom email template
  CUSTODIAL, // custodial account, kms
}

export enum AccountKeyType {
  MPC = 0,
  SNAP,
  METAMASK,
  AWS_KMS,
  Web3Auth,
}

// 0=synced,1:server synced, 2:not received sync email, 3:not synced
export enum SyncStatusEnum {
  Synced,
  ServerSynced,
  NotReceived,
  NotSynced,
}

export enum SignType {
  PersonalSign = 0,
  EIP712Sign,
  Transaction,
}

export enum AuditStatus {
  Approved = 0,
  Rejected,
  Confirming,
}

export enum SignMessageType {
  v1 = 'V1',
  v4 = 'V4',
}
